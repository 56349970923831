import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Peer } from 'peerjs';
import { Input } from 'antd';

interface ILayerProps {}

/**
 * Video Meeting
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const VideoMeeting = ({}: PropsWithChildren<ILayerProps>) => {
  const [targetPeerId, setTargetPeerId] = useState<string>('');
  const [myPeerId, setMyPeerId] = useState<string>('');

  const peerRef = useRef<any>(null);
  const remoteVideoRef = useRef<any>(null);

  const connectToPeer = () => {
    let peerId = targetPeerId;

    let conn = peerRef.current.connect(peerId);

    conn.on('data', (data: any) => {
      console.log('> receive data:', data);
    });

    conn.on('open', () => {
      conn.send('hi!');
    });

    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream: any) => {
        let call = peerRef.current.call(peerId, stream);

        call.on('stream', (remoteStream: any) => {
          remoteVideoRef.current.srcObject = remoteStream;
        });
      })
      .catch((error: any) => {
        console.log('> mediaDevices.getUserMedia error:', error);
      });
  };

  const connectToPeerByShare = () => {
    let peerId = targetPeerId;

    let conn = peerRef.current.connect(peerId);

    conn.on('data', (data: any) => {
      console.log('> receive data:', data);
    });

    conn.on('open', () => {
      conn.send('hi!');
    });

    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
      })
      .then((stream: any) => {
        let call = peerRef.current.call(peerId, stream);

        call.on('stream', (remoteStream: any) => {
          remoteVideoRef.current.srcObject = remoteStream;
        });
      })
      .catch((error: any) => {
        console.log('> mediaDevices.getUserMedia error:', error);
      });
  };

  useEffect(() => {
    // peerRef.current = new Peer({
    //   host: '/',
    //   port: 2002,
    //   path: '/peerjs/myapp',
    // });

    // 임시!
    // ci/cd 테스트용 소스입니다.
    // 삭제해야 함
    let tmp: any = '';

    peerRef.current = new Peer({
      host: 'layer-api.easysend.co.kr',
      port: 443,
      secure: true,
      path: '/peerjs/myapp',
    });

    peerRef.current.on('open', (id: any) => {
      console.log('> my peer id:', id);

      setMyPeerId(id);
    });

    peerRef.current.on('error', (error: any) => {
      console.log('> my peer error:', error);
    });

    peerRef.current.on('connection', (conn: any) => {
      conn.on('data', (data: any) => {
        console.log('> connection.data:', data);
      });

      conn.on('open', () => {
        conn.send('hello!');
      });
    });

    peerRef.current.on('call', (call: any) => {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream: any) => {
          call.answer(stream);

          call.on('stream', (remoteStream: any) => {
            remoteVideoRef.current.srcObject = remoteStream;
          });
        })
        .catch((err) => {
          console.error('Failed to get local stream', err);
        });
    });

    return () => {};
  }, []);

  return (
    <div className="bg-white w-full h-full space-y-5">
      <div className="flex justify-center items-center">
        <video
          ref={remoteVideoRef}
          className="w-full h-full border"
          autoPlay
        ></video>
      </div>

      <div className="px-2 leading-none space-y-1">
        <div className="flex justify-start items-center">
          <span className="text-xs text-gray-600">My Peer ID</span>
        </div>
        <div className="flex justify-start items-center">
          <span className="text-base font-semibold text-gray-800">
            {myPeerId}
          </span>
        </div>
      </div>

      <div className="px-2 leading-none space-y-1">
        <div className="flex justify-start items-center">
          <span className="text-xs text-gray-600">Target Peer ID</span>
        </div>
        <div className="flex justify-start items-center">
          <Input
            onChange={(event: any) => setTargetPeerId(event.target.value)}
            value={targetPeerId}
            className=""
          />
        </div>
      </div>

      <div className="px-2">
        <div className="flex justify-between items-center space-x-2">
          <div
            onClick={connectToPeer}
            className="button-event w-full px-2 py-2 flex justify-center items-center bg-blue-600 rounded"
          >
            <span className="text-sm font-semibold text-white">
              Connect: Camera
            </span>
          </div>

          <div
            onClick={connectToPeerByShare}
            className="button-event w-full px-2 py-2 flex justify-center items-center bg-teal-600 rounded"
          >
            <span className="text-sm font-semibold text-white">
              Connect: Share
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoMeeting;
