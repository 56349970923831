import React, { useCallback, useEffect } from 'react';
import 'reactflow/dist/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactFlow, { Handle, NodeProps, Position, useNodeId } from 'reactflow';
import { Button, Input, InputNumber, Slider } from 'antd';

interface INodeProps {
  label: string;
  text: string;
  modifyData: any;
  sendData: any;
}

/**
 * Process Flow > Send Window Message Node
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const SendWindowMessageNode = ({ data }: NodeProps<INodeProps>) => {
  const nodeId = useNodeId();

  const handleInput_onChange = (event: any) => {
    // 노드의 데이터를 부모 컴포넌트에서 수정함
    data.modifyData([
      {
        nodeId: nodeId,
        objectKey: 'text',
        value: event.target.value,
      },
    ]);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {/* Input: In */}
      <Handle
        id="in"
        type="target"
        position={Position.Left}
        className="relative !top-10 !w-2 !h-2 !bg-gray-600 z-20"
      >
        <div className="absolute left-3 top-1/2 -translate-y-1/2 flex justify-start items-center leading-none">
          <span className="text-xs text-white font-semibold truncate">In</span>
        </div>
      </Handle>

      {/* Node */}
      <div className="flex flex-col w-48 bg-black border border-black rounded overflow-hidden z-10">
        <div className="w-full h-6">
          <div className="px-2 w-full h-full flex justify-start items-center bg-gradient-to-b from-purple-600 via-black to-black space-x-2">
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fas', 'paper-plane']}
                className="w-3 h-3 text-white"
              />
            </div>

            {/* 제목 */}
            <span className="text-xs text-white font-semibold">
              {data.label}
            </span>
          </div>
        </div>

        {/* 내용 */}
        <div className="relative flex-grow w-full bg-slate-700 rounded">
          {/* 핸들 영역 */}
          <div className="w-full h-8 border-b border-slate-600"></div>

          {/* 내용 영역 */}
          <div className="px-2 py-2 flex justify-center items-center">
            <Input
              onChange={handleInput_onChange}
              value={data.text}
              size="small"
              className="w-full !h-full nodrag nowheel"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SendWindowMessageNode;
