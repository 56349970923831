import { Progress } from 'antd';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from './lottieLoading.json';

interface ILayerProps {}

/**
 * WASM 테스트
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Wasm = ({}: PropsWithChildren<ILayerProps>) => {
  const [jsCount, setJsCount] = useState<number>(0);
  const [wasmCount, setWasmCount] = useState<number>(0);

  const jsCountRef = useRef<number>(0);
  const wasmCountRef = useRef<number>(0);

  const jsCountRatioRef = useRef<number>(0);
  const wasmCountRatioRef = useRef<number>(0);

  const [jsTime, setJsTime] = useState<number>(0);
  const [wasmTime, setWasmTime] = useState<number>(0);

  const [jsAnswer, setJsAnswer] = useState<number>(0);
  const [wasmAnswer, setWasmAnswer] = useState<number>(0);

  const processCount2 = (value: number): number => {
    if (value <= 0) {
      return 0;
    } else if (value === 1) {
      return 1;
    }

    jsCountRef.current += 1;

    let tmpCountRatio: number = parseInt(
      ((jsCountRef.current * 100) / 102334154).toString(),
    );

    if (tmpCountRatio !== jsCountRatioRef.current) {
      jsCountRatioRef.current = tmpCountRatio;

      let jsCountRatioText = document.querySelector(
        '#js-count-ratio',
      ) as HTMLSpanElement;
      jsCountRatioText.innerText = jsCountRatioRef.current.toString();
    }

    return processCount2(value - 1) + processCount2(value - 2);
  };

  const processCount = (value: number): number => {
    if (value <= 0) {
      return 0;
    } else if (value === 1) {
      return 1;
    }

    jsCountRef.current += 1;

    let tmpCountRatio: number = (jsCountRef.current * 100) / 102334154;

    // if (tmpCountRatio !== jsCountRatioRef.current) {
    //   jsCountRatioRef.current = tmpCountRatio;
    //
    //   let jsCountRatioText = document.querySelector(
    //     '#js-count-ratio',
    //   ) as HTMLSpanElement;
    //   jsCountRatioText.innerText = jsCountRatioRef.current.toString();
    // }

    return processCount(value - 1) + processCount(value - 2);
  };

  // JavaScript로 계산을 시작함
  const handleJsCountButton_onClick = () => {
    let startTime: Date = new Date();

    jsCountRef.current = 0;
    jsCountRatioRef.current = 0;

    let result: number = processCount2(39);

    // setJsCount(jsCountRatioRef.current);
    setJsCount(100);

    let jsCountText = document.querySelector('#js-count') as HTMLSpanElement;
    jsCountText.innerText = jsCountRef.current.toLocaleString();

    let endTime: Date = new Date();

    let resultTime: any = endTime.getTime() - startTime.getTime();

    setJsTime(resultTime / 1000);
    setJsAnswer(result);

    // console.log('> resultTime:', resultTime);

    console.log('> result:', result);
    // console.log('> result count:', jsCountRef.current);
  };

  // WebAssembly로 계산을 시작함
  const handleWasmCountButton_onClick = async () => {
    // let tmpSum: number = window.sumTwoNumber(100, 2);
    //
    // console.log('> tmpSum:', tmpSum);

    let startTime: Date = new Date();

    wasmCountRef.current = 0;
    wasmCountRatioRef.current = 0;

    let result: number = window.wasmProcessCount(39);

    setWasmCount(100);

    // let jsCountText = document.querySelector('#js-count') as HTMLSpanElement;
    // jsCountText.innerText = jsCountRef.current.toLocaleString();

    let endTime: Date = new Date();

    let resultTime: any = endTime.getTime() - startTime.getTime();

    setWasmTime(resultTime / 1000);
    setWasmAnswer(result);

    // console.log('> resultTime:', resultTime);

    // console.log('> result:', result);
    // console.log('> result count:', jsCountRef.current);
  };

  useEffect(() => {
    const loadWasm = async () => {
      const go = new Go();

      // WASM 파일을 불러와서 WASM과 통신함
      await WebAssembly.instantiateStreaming(
        fetch('/sample.wasm'),
        go.importObject,
      ).then(async (result: any) => {
        // WASM 파일을 메모리에 올림
        await go.run(result.instance);
      });
    };

    loadWasm();

    return () => {};
  }, []);

  return (
    <div className="bg-white w-full h-full">
      <div className="w-full h-full flex justify-between items-center space-x-2">
        {/* JavaScript */}
        <div className="w-full h-full px-4 py-3 bg-gray-100 rounded space-y-5">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-xl text-gray-600 font-bold">
                JavaScript
              </span>
            </div>

            <div>
              <div
                onClick={handleJsCountButton_onClick}
                className="button-event px-5 py-1 flex justify-center items-center bg-blue-600 rounded"
              >
                <span className="text-sm font-semibold text-white">Start</span>
              </div>
            </div>
          </div>
          {/*<div>*/}
          {/*  <Progress*/}
          {/*    percent={jsCount}*/}
          {/*    strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}*/}
          {/*    showInfo={false}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="">
            {/* 그래프 */}
            <div>
              {/*<div className="w-full h-2 bg-gray-200 rounded-full"></div>*/}
              <Progress
                percent={jsCount}
                strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                showInfo={false}
              />
            </div>

            {/* 개수, 비율 */}
            <div className="flex justify-between items-center">
              <div>
                <span id="js-count" className="text-sm text-gray-600">
                  0
                </span>
              </div>
              <div>
                <span id="js-count-ratio" className="text-sm text-gray-600">
                  0
                </span>
                <span className="text-sm text-gray-600">%</span>
              </div>
            </div>
          </div>

          {/* 계산결과 */}
          {jsTime > 0 && (
            <div className="flex justify-center items-center">
              <span className="text-xl text-gray-700 font-bold">
                Result: {jsAnswer}
              </span>
            </div>
          )}

          {/* 소요시간 */}
          {jsTime > 0 && (
            <div className="flex justify-center items-center">
              <span className="text-2xl text-gray-700 font-bold">
                {jsTime}초
              </span>
            </div>
          )}

          {/*<div className="w-96 h-96 flex justify-center items-center">*/}
          {/*  <Lottie animationData={loadingAnimation} loop={true} />*/}
          {/*</div>*/}
        </div>

        {/* WebAssembly */}
        <div className="w-full h-full px-4 py-3 bg-gray-100 rounded space-y-5">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-xl text-gray-600 font-bold">
                WebAssembly
              </span>
            </div>

            <div>
              <div
                onClick={handleWasmCountButton_onClick}
                className="button-event px-5 py-1 flex justify-center items-center bg-blue-600 rounded"
              >
                <span className="text-sm font-semibold text-white">Start</span>
              </div>
            </div>
          </div>
          {/*<div>*/}
          {/*  <Progress*/}
          {/*    percent={jsCount}*/}
          {/*    strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}*/}
          {/*    showInfo={false}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="">
            {/* 그래프 */}
            <div>
              {/*<div className="w-full h-2 bg-gray-200 rounded-full"></div>*/}
              <Progress
                percent={wasmCount}
                strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                showInfo={false}
              />
            </div>

            {/* 개수, 비율 */}
            <div className="flex justify-between items-center">
              <div>
                {/*<span id="wasm-count" className="text-sm text-gray-600">*/}
                {/*  0*/}
                {/*</span>*/}
              </div>
              <div>
                <span id="wasm-count-ratio" className="text-sm text-gray-600">
                  {wasmCount}
                </span>
                <span className="text-sm text-gray-600">%</span>
              </div>
            </div>
          </div>

          {/* 계산결과 */}
          {wasmTime > 0 && (
            <div className="flex justify-center items-center">
              <span className="text-xl text-gray-700 font-bold">
                Result: {wasmAnswer}
              </span>
            </div>
          )}

          {/* 소요시간 */}
          {wasmTime > 0 && (
            <div className="flex justify-center items-center">
              <span className="text-2xl text-gray-700 font-bold">
                {wasmTime}초
              </span>
            </div>
          )}

          {/*<div className="w-96 h-96 flex justify-center items-center">*/}
          {/*  <Lottie animationData={loadingAnimation} loop={true} />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default Wasm;
