import React, { useCallback, useEffect } from 'react';
import 'reactflow/dist/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactFlow, { Handle, NodeProps, Position, useNodeId } from 'reactflow';
import { Button, InputNumber, Slider } from 'antd';

interface INodeProps {
  label: string;
  toggle: boolean;
  currentToggle: boolean;
  secNumber: number;
  modifyData: any;
  sendData: any;
}

/**
 * Process Flow > Tick Node
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const TickNode = ({ data }: NodeProps<INodeProps>) => {
  const nodeId = useNodeId();

  const handleSecNumber_onChange = (event: any) => {
    // 노드의 데이터를 부모 컴포넌트에서 수정함
    data.modifyData([
      {
        nodeId: nodeId,
        objectKey: 'secNumber',
        value: event,
      },
    ]);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {/* Input: In */}
      <Handle
        id="in"
        type="target"
        position={Position.Left}
        className="relative !top-10 !w-2 !h-2 !bg-gray-600 z-20"
      >
        <div className="absolute left-3 top-1/2 -translate-y-1/2 flex justify-start items-center leading-none">
          <span className="text-xs text-white font-semibold truncate">In</span>
        </div>
      </Handle>

      {/* Node */}
      <div className="flex flex-col w-48 bg-black border border-black rounded overflow-hidden z-10">
        <div className="w-full h-6">
          <div className="px-2 w-full h-full flex justify-start items-center bg-gradient-to-b from-lime-600 via-black to-black space-x-2">
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fas', 'shuffle']}
                className="w-3 h-3 text-white"
              />
            </div>

            {/* 제목 */}
            <span className="text-xs text-white font-semibold">
              {data.label}
            </span>
          </div>
        </div>

        {/* 내용 */}
        <div className="relative flex-grow w-full bg-slate-700 rounded">
          {/* 핸들 영역 */}
          <div className="w-full h-8 border-b border-slate-600"></div>

          {/* 내용 영역 */}
          <div className="px-2 py-2 flex justify-center items-center">
            <div className="space-y-1">
              {/* 동그라미 */}
              <div className="w-full flex justify-center items-center space-x-2">
                {data.toggle === true ? (
                  <div className="w-3 h-3 bg-lime-400 rounded-full"></div>
                ) : (
                  <div className="w-3 h-3 bg-gray-400 rounded-full"></div>
                )}
              </div>

              {/* 숫자 입력 */}
              <div className="flex justify-between items-center space-x-2">
                {/* Sec(초) Number */}
                <div className="space-y-1">
                  <div className="px-1 flex justify-start items-center leading-none">
                    <span className="text-xs text-white">Sec(초)</span>
                  </div>

                  <InputNumber
                    onChange={handleSecNumber_onChange}
                    value={data.secNumber}
                    size="small"
                    className="w-full !h-full nodrag nowheel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Output: Out */}
      <Handle
        id="out"
        type="source"
        position={Position.Right}
        className="relative !top-10 !w-2 !h-2 !bg-gray-600 z-20"
      >
        <div className="absolute right-3 top-1/2 -translate-y-1/2 flex justify-end items-center leading-none">
          <span className="text-xs text-white font-semibold truncate">Out</span>
        </div>
      </Handle>
    </>
  );
};

export default TickNode;
