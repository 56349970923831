import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ILayerProps {}

/**
 * PDF Drag Item
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PdfDragItem = ({}: PropsWithChildren<ILayerProps>) => {
  const handleItem_onDragStart = (event: any, url: string) => {
    event.dataTransfer.setData('application/dragItem', url);
    event.dataTransfer.effectAllowed = 'move';
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="bg-white w-full h-full">
      {/* Item */}
      <div
        onDragStart={(event: any) =>
          handleItem_onDragStart(event, 'Office-365-Intro.-and-Tutorials.pdf')
        }
        draggable
        className="flex justify-start items-center cursor-move"
      >
        {/* 아이콘 */}
        <div className="w-10 h-10 flex justify-center items-center">
          <FontAwesomeIcon
            icon={['fal', 'file']}
            className="w-5 h-5 text-blue-500"
          />
        </div>

        {/* 제목 */}
        <span className="text-xs text-gray-600 font-semibold">PDF File #1</span>
      </div>

      {/* Item */}
      <div
        onDragStart={(event: any) =>
          handleItem_onDragStart(event, 'MFL71941803_GA2.pdf')
        }
        draggable
        className="flex justify-start items-center cursor-move"
      >
        {/* 아이콘 */}
        <div className="w-10 h-10 flex justify-center items-center">
          <FontAwesomeIcon
            icon={['fal', 'file']}
            className="w-5 h-5 text-blue-500"
          />
        </div>

        {/* 제목 */}
        <span className="text-xs text-gray-600 font-semibold">PDF File #2</span>
      </div>
    </div>
  );
};

export default PdfDragItem;
