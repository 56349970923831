import React, { ReactNode, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';

import MainPage from './pages/Main.page';
import NotFound from './pages/common/NotFound';

// FontAwesome 아이콘을 불러옴
library.add(fab, fad, fal, fas, fat, far);

function App() {
  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* 메인 */}
        <Route path="/" element={<MainPage />} />

        {/* 페이지를 찾을 수 없음 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
