import { atom } from 'recoil';
import { IConfig } from '../interfaces/app.interface';

/**
 * 환경설정
 */
export const configStore = atom({
  key: 'configStore',
  default: {
    enableShortCut: true,
  } as IConfig,
});
