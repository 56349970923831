import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createEmotionCache, MantineProvider } from '@mantine/core';
// import { ModalsProvider } from '@mantine/modals';
import { EmotionCache } from '@emotion/react';
import './styles/index.style.css';
import './styles/app.style.css';
import './i18n/config';
import App from './App';
// import { Notifications } from '@mantine/notifications';

// Tailwind CSS의 버튼 투명화 기본값 때문에 Mantine의 버튼이 투명으로 적용되어 버튼이 보이지 않는 문제가 발생함
// Mantine의 emotionCache를 사용하여 Tailwind CSS의 스타일을 먼저 불러온 후, Mantine의 스타일을 덮어서 불러오도록 함
// 참고: https://v5.mantine.dev/theming/emotion-cache/
// const myCache: EmotionCache = createEmotionCache({
//   key: 'mantine',
//   prepend: false,
// });

// 타입이 없는 경우를 해결함
declare global {
  interface Window {
    sumTwoNumber: Function;
    wasmProcessCount: Function;
  }
}

// React Query를 생성함
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    {/*<QueryClientProvider client={queryClient}>*/}
    {/*  <ReactQueryDevtools initialIsOpen={false} />*/}
    {/*<MantineProvider*/}
    {/*  withCSSVariables*/}
    {/*  withGlobalStyles*/}
    {/*  withNormalizeCSS*/}
    {/*  emotionCache={myCache}*/}
    {/*>*/}
    {/*<Notifications />*/}
    {/*<ModalsProvider>*/}
    <App />
    {/*</ModalsProvider>*/}
    {/*</MantineProvider>*/}
    {/*</QueryClientProvider>*/}
  </RecoilRoot>,
  // </React.StrictMode>
);
