import React, { useEffect } from 'react';
import * as appUtil from '../utils/app.util';
import { Button } from 'antd';
import { Wallpaper } from '../layouts/Wallpaper';
import { TaskBar } from '../layouts/TaskBar';
import { Desktop } from '../layouts/Desktop';
import { LayerList } from '../layouts/LayerList';

/**
 * 메인
 * @constructor
 */
const MainPage = () => {
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="relative w-screen h-screen">
      {/* 실제 작업 영역 */}
      <div className="absolute left-0 top-0 w-full h-full flex flex-col z-20">
        {/* 바탕화면 */}
        <Desktop />

        {/* 작업표시줄 */}
        <TaskBar />
      </div>

      {/* 배경화면 */}
      {/*<Wallpaper wallpaperType="color" wallpaperSource="bg-slate-800" />*/}
      <Wallpaper wallpaperType="image" wallpaperSource="/images/bg-1.jpg" />
    </div>
  );
};

export default MainPage;
