import React, { useCallback, useEffect } from 'react';
import 'reactflow/dist/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactFlow, { Handle, NodeProps, Position, useNodeId } from 'reactflow';
import { Button } from 'antd';

interface INodeProps {
  label: string;
  button: {
    label: string;
  };
  sendData: any;
}

/**
 * Process Flow > Button Node
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ButtonNode = ({ data }: NodeProps<INodeProps>) => {
  const nodeId = useNodeId();

  // 버튼을 클릭함
  const handleButton_onClick = useCallback((event: any) => {
    data.sendData({
      nodeId: nodeId,
      handleId: 'out',
      data: null,
    });
  }, []);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {/*<Handle*/}
      {/*  id="a"*/}
      {/*  type="target"*/}
      {/*  position={Position.Left}*/}
      {/*  style={{*/}
      {/*    top: 20,*/}
      {/*  }}*/}
      {/*  // isConnectable={false}*/}
      {/*  className="!w-2 !h-2 !bg-blue-600 !rounded-none"*/}
      {/*>*/}
      {/*  <div className="absolute left-2 top-1/2 -translate-y-1/2 flex justify-start items-center bg-rose-300 leading-none">*/}
      {/*    <span className="text-xs truncate">{nodeId}</span>*/}
      {/*  </div>*/}
      {/*</Handle>*/}
      {/*<Handle*/}
      {/*  id="b"*/}
      {/*  type="target"*/}
      {/*  position={Position.Left}*/}
      {/*  // className="relative w-4 h-4 !bg-blue-900 z-20"*/}
      {/*>*/}
      {/*  <div className="absolute left-2 top-1/2 -translate-y-1/2 flex justify-start items-center bg-blue-300 leading-none">*/}
      {/*    <span className="text-xs truncate">Handle.b</span>*/}
      {/*  </div>*/}
      {/*</Handle>*/}

      {/* Node */}
      <div className="flex flex-col w-36 bg-black border border-black rounded overflow-hidden z-10">
        <div className="w-full h-6">
          <div className="px-2 w-full h-full flex justify-start items-center bg-gradient-to-b from-indigo-600 via-black to-black space-x-2">
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fas', 'play']}
                className="w-3 h-3 text-white"
              />
            </div>

            {/* 제목 */}
            <span className="text-xs text-white font-semibold">
              {data.label}
            </span>
          </div>
        </div>

        {/* 내용 */}
        <div className="relative flex-grow w-full bg-slate-700 rounded">
          {/* 핸들 영역 */}
          <div className="w-full h-8 border-b border-slate-600"></div>

          {/* 내용 영역 */}
          <div className="px-2 py-2 flex justify-center items-center">
            <Button
              onClick={handleButton_onClick}
              className="button-event !w-full !bg-blue-500 border !border-blue-500 !text-white nodrag nowheel"
            >
              <span className="text-sm">{data.button.label}</span>
            </Button>
          </div>
        </div>
      </div>

      {/* Output: Out */}
      <Handle
        id="out"
        type="source"
        position={Position.Right}
        // style={{ top: 40 }}
        className="relative !top-10 !w-2 !h-2 !bg-gray-600 z-20"
      >
        <div className="absolute right-3 top-1/2 -translate-y-1/2 flex justify-end items-center leading-none">
          <span className="text-xs text-white font-semibold truncate">Out</span>
        </div>
      </Handle>
    </>
  );
};

export default ButtonNode;
