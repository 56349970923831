import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Layer } from '../../managers/Layer';
import { ILayer } from '../../interfaces/app.interface';
import {
  addLayerStore,
  draggingLayerStore,
  splitDraggingLayerStore,
} from '../../stores/layer.store';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion';

interface ILayoutProps {
  areaType: 'left' | 'right';
}

/**
 * 바탕화면의 분할 레이어 영역
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const SplitLayerArea = ({
  areaType = 'left',
}: PropsWithChildren<ILayoutProps>) => {
  // 드래그 중인 레이어 저장소를 정의함
  const [draggingLayer, setDraggingLayer] = useRecoilState<string | null>(
    draggingLayerStore,
  );

  // 드래그 중인 레이어를 화면 양쪽 끝으로 이동 저장소를 정의함
  const [splitDraggingLayer, setSplitDraggingLayer] = useRecoilState<
    'left' | 'right' | null
  >(splitDraggingLayerStore);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {/* 왼쪽 영역 */}
      <motion.div
        initial="close"
        animate={splitDraggingLayer === 'left' ? 'open' : 'close'}
        variants={{
          open: {
            display: 'block',
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
          },
          close: {
            opacity: 0,
            x: 0,
            y: 0,
            scale: 0,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
            transitionEnd: { display: 'none' },
          },
        }}
        className="absolute top-2 left-2 bottom-2 w-1/2 z-40"
      >
        <div
          // className="absolute top-2 left-2 bottom-2 w-1/2 bg-white/50 border-4 border-white rounded-xl animate-pulse z-10"
          className="w-full h-full bg-white/50 border-4 border-white rounded-xl animate-pulse"
        ></div>
      </motion.div>

      {/* 오른쪽 영역 */}
      <motion.div
        initial="close"
        animate={splitDraggingLayer === 'right' ? 'open' : 'close'}
        variants={{
          open: {
            display: 'block',
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
          },
          close: {
            opacity: 0,
            x: 0,
            y: 0,
            scale: 0,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
            transitionEnd: { display: 'none' },
          },
        }}
        className="absolute top-2 right-2 bottom-2 w-1/2 z-30"
      >
        <div className="w-full h-full bg-white/50 border-4 border-white rounded-xl animate-pulse"></div>
      </motion.div>
    </>
  );
};

export default SplitLayerArea;
