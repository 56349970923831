import React, { PropsWithChildren, useEffect, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import '../../styles/excel.style.css';
import { useElementSize } from '@mantine/hooks';

interface ILayerProps {
  type: 'free' | 'price' | 'google';
}

/**
 * Excel Sheet
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ExcelSheet = ({ type }: PropsWithChildren<ILayerProps>) => {
  const data = [
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
    ],
  ];

  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  const [waitDraw, setWaitDraw] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setWaitDraw(true);
    }, 300);

    return () => {};
  }, []);

  useEffect(() => {
    // 유료 광고 삭제
    let tmpAd1: any = document.querySelector(
      "[style*='font-family: Helvetica Neue, Helvetica, Arial']",
    ) as HTMLDivElement;

    if (tmpAd1 !== null) {
      tmpAd1.remove();
    }

    // 유료 광고 삭제
    let tmpAd2: any = document.querySelector(
      "[style*='background-color: rgba(0, 0, 0, 0.5)']",
    ) as HTMLDivElement;

    if (tmpAd2 !== null) {
      tmpAd2.remove();
    }

    return () => {};
  }, [waitDraw]);

  return (
    <div ref={contentRef} className="bg-white w-full h-full">
      {waitDraw && type === 'free' && (
        <Spreadsheet data={data} className="w-full h-full" />
      )}
      {waitDraw && type === 'price' && (
        <SpreadsheetComponent width="100%" height={contentHeight} />
      )}
      {waitDraw && type === 'google' && (
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSSkSxkvlgKprk8rwCeCtfHVyT7qy8j3piHBbl0g0fiJYRbOwezasF9Wc-tDRj71luh-N50couI1ZCM/pubhtml?widget=true&amp;headers=false"
          className="w-full h-full"
        ></iframe>
      )}
    </div>
  );
};

export default ExcelSheet;
