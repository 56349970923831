import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ILayer,
  ILayerSplitWay,
  IPosition,
  ISize,
} from '../../interfaces/app.interface';
import {
  addLayerStore,
  draggingLayerStore,
  layerStore,
  maximizedLayerStore,
  minimizedLayerStore,
  modifyLayerStore,
  removeAllLayerStore,
  removeLayerStore,
  restoreLayerStore,
  selectedLayerStore,
} from '../../stores/layer.store';
import { LayerBase } from '../../components/LayerBase';

interface IManagerProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 레이어
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Layer = ({ data, onClick }: PropsWithChildren<IManagerProps>) => {
  // 레이어 저장소를 정의함
  const [layer, setLayer] = useRecoilState<ILayer[]>(layerStore);

  // 추가할 레이어 저장소를 정의함
  const [addLayer, setAddLayer] = useRecoilState<ILayer | null>(addLayerStore);

  // 수정할 레이어 저장소를 정의함
  const [modifyLayer, setModifyLayer] = useRecoilState<ILayer | null>(
    modifyLayerStore,
  );

  // 삭제할 레이어 저장소를 정의함
  const [removeLayer, setRemoveLayer] = useRecoilState<string | null>(
    removeLayerStore,
  );

  // 삭제할 전체 레이어 저장소를 정의함
  const [removeAllLayer, setRemoveAllLayer] =
    useRecoilState<boolean>(removeAllLayerStore);

  // 선택한 레이어 저장소를 정의함
  const [selectedLayer, setSelectedLayer] = useRecoilState<string | null>(
    selectedLayerStore,
  );

  // 드래그 중인 레이어 저장소를 정의함
  const [draggingLayer, setDraggingLayer] = useRecoilState<string | null>(
    draggingLayerStore,
  );

  // 최소화한 레이어를 정의함
  const [minimizedLayer, setMinimizedLayer] = useRecoilState<string | null>(
    minimizedLayerStore,
  );

  // 최대화한 레이어를 정의함
  const [maximizedLayer, setMaximizedLayer] = useRecoilState<string | null>(
    maximizedLayerStore,
  );

  // 원래 크기 및 위치로 복원한 레이어를 정의함
  const [restoreLayer, setRestoreLayer] = useRecoilState<string | null>(
    restoreLayerStore,
  );

  // 팝업의 출력 여부를 정의함
  const [visiblePopup, setVisiblePopup] = useState<string[]>([]);

  // 레이어가 화면 중앙일 때의 위치를 계산함
  const getLayerCenterPosition = (size: ISize): IPosition => {
    let x: number =
      size.width === 0 ? 0 : window.innerWidth / 2 - size.width / 2;
    let y: number =
      size.height === 0 ? 0 : window.innerHeight / 2 - size.height / 2;

    // 위치 px 출력 방식(소수점 좌표)에 따라 레이어 모양이 일그러지는 경우가 발생하여 정수로 처리함
    x = Math.round(x);
    y = Math.round(y);

    return { x, y };
  };

  // 레이어가 화면 밖으로 벗어났을 때의 위치를 계산하여 보정함
  const getLayerNewPosition = (size: ISize, position: IPosition): IPosition => {
    // 레이어가 왼쪽, 윗쪽 밖으로 벗어났으면 위치를 보정함
    let x: number =
      size.width + position.x > 20 ? position.x : -size.width + 20;
    let y: number = position.y > 0 ? position.y : 0;

    // 인자를 갱신함
    position.x = x;
    position.y = y;

    // 레이어가 오른쪽, 아랫쪽 밖으로 벗어났으면 위치를 보정함
    x =
      window.innerWidth - position.x > 20 ? position.x : window.innerWidth - 20;
    y =
      window.innerHeight - position.y > 90
        ? position.y
        : window.innerHeight - 90;

    // 위치 px 출력 방식(소수점 좌표)에 따라 레이어 모양이 일그러지는 경우가 발생하여 정수로 처리함
    x = Math.round(x);
    y = Math.round(y);

    return { x, y };
  };

  // 레이어의 새 최상단 위치를 불러옴
  const getMaxZIndex = (id: string = ''): number => {
    // 최상단의 레이어를 불러옴
    let maxZIndexLayer = _.maxBy(layer, 'zIndex') as ILayer;

    // 레이어가 하나도 없을 경우에는 기본값을 적용함
    if (maxZIndexLayer === undefined) {
      return 1;
    }

    // 선택한 레이어가 최상단의 레이어가 아니라면 선택한 레이어를 최상단으로 변경함
    if (maxZIndexLayer.id !== id) {
      // 최상단의 레이어보다 +1 높은 위치로 이동함
      let newZIndex = maxZIndexLayer.zIndex! + 1;

      return newZIndex;
    }

    return maxZIndexLayer.zIndex!;
  };

  useEffect(() => {}, []);

  // 레이어가 갱신됨
  useEffect(() => {
    // console.log('> layer: ', layer);
  }, [layer]);

  // 레이어가 추가됨
  useEffect(() => {
    if (addLayer === null) {
      return;
    }

    // 레이어 아이디를 지정했을 경우, 중복되는 레이어라면 새 레이어로 추가하지 않음
    if (addLayer.id !== undefined || addLayer.id !== '') {
      let findLayer = _.find(layer, { id: addLayer.id }) as ILayer;

      if (findLayer !== undefined) {
        return;
      }
    }

    // 레이어의 기본값을 생성함
    let newLayer: ILayer = {
      id: addLayer.id || uuidv4(),
      icon: addLayer.icon || ['fas', 'star'],
      title: addLayer.title || '제목 없음',
      size: {
        width: addLayer.size?.width || 500,
        height: addLayer.size?.height || 400,
      },
      minSize: {
        width: addLayer.minSize?.width || 300,
        height: addLayer.minSize?.height || 44,
      },
      maxSize: {
        width: addLayer.maxSize?.width || 0,
        height: addLayer.maxSize?.height || 0,
      },
      position: {
        x: addLayer.position?.x || 0,
        y: addLayer.position?.y || 0,
      },
      minimized: addLayer.minimized || false,
      maximized: addLayer.maximized || false,
      showMinimizeButton: addLayer.showMinimizeButton || true,
      showMaximizeButton: addLayer.showMaximizeButton || true,
      showCloseButton: addLayer.showCloseButton || true,
      resizing: addLayer.resizing || true,
      lockPosition: addLayer.lockPosition || false,
      doubleClickToMaximize: addLayer.doubleClickToMaximize || true,
      zIndex: addLayer.zIndex || getMaxZIndex(),
      children: addLayer.children || <></>,
    };

    // 레이어의 위치를 보정함
    let newPosition: IPosition = { x: 0, y: 0 };

    // 위치가 없으면 화면 중간에 출력함
    if (addLayer.position?.x === undefined) {
      // 레이어가 화면 중앙일 때의 위치를 계산함
      newPosition = getLayerCenterPosition(newLayer.size!);

      // 레이어의 새 위치에 적용함
      newLayer.position = newPosition;
    }

    // 레이어가 화면 밖으로 벗어났을 때의 위치를 계산하여 보정함
    newPosition = getLayerNewPosition(newLayer.size!, newLayer.position!);

    // 레이어의 새 위치에 적용함
    newLayer.position = newPosition;

    // 레이어를 추가함
    setLayer((pre: ILayer[]) => [...pre, newLayer]);

    // 선택한 레이어를 기억함
    setSelectedLayer(newLayer.id!);

    // 추가한 레이어를 임시 공간에서 삭제함
    setAddLayer(null);
  }, [addLayer]);

  // 레이어가 수정됨
  useEffect(() => {
    if (modifyLayer === null) {
      return;
    }

    // 수정할 기존 레이어를 불러옴
    let newLayer: ILayer = _.cloneDeep(
      _.find(layer, {
        id: modifyLayer.id,
      }),
    ) as ILayer;

    // 수정할 레이어와 데이터를 합침
    newLayer = _.cloneDeep(Object.assign(newLayer, modifyLayer));

    // 레이어가 화면 밖으로 벗어났을 때의 위치를 계산하여 보정함
    let newPosition: IPosition = getLayerNewPosition(
      newLayer.size!,
      newLayer.position!,
    );

    // 레이어의 새 위치에 적용함
    newLayer.position = newPosition;

    // 수정할 레이어의 순서를 불러옴
    let modifyLayerIndex: number = _.findIndex(layer, { id: newLayer.id });

    // 레이어를 수정함
    setLayer((pre: ILayer[]) => [
      ...pre.slice(0, modifyLayerIndex),
      newLayer,
      ...pre.slice(modifyLayerIndex + 1),
    ]);

    // 수정한 레이어를 임시 공간에서 삭제함
    setModifyLayer(null);
  }, [modifyLayer]);

  // 레이어가 삭제됨
  useEffect(() => {
    if (removeLayer === null) {
      return;
    }

    // 삭제할 레이어의 순서를 불러옴
    let removeLayerIndex: number = _.findIndex(layer, { id: removeLayer });

    // 레이어를 삭제함
    setLayer((pre: ILayer[]) => [
      ...pre.slice(0, removeLayerIndex),
      ...pre.slice(removeLayerIndex + 1),
    ]);

    // 삭제한 레이어를 제외한 나머지 레이어 중, 최상단의 레이어를 불러옴
    let maxZIndexLayer = _.maxBy(
      _.filter(layer, (item: ILayer) => item.id !== removeLayer),
      'zIndex',
    ) as ILayer;

    // 레이어가 하나도 없을 경우에는 기본값을 적용함
    if (maxZIndexLayer === undefined) {
      // 선택한 레이어를 기억함
      setSelectedLayer(null);
    } else {
      // 선택한 레이어를 기억함
      setSelectedLayer(maxZIndexLayer.id!);
    }

    // 삭제한 레이어를 임시 공간에서 삭제함
    setRemoveLayer(null);
  }, [removeLayer]);

  // 레이어가 선택됨
  useEffect(() => {
    if (selectedLayer === null) {
      return;
    }

    // 레이어의 새 최상단 위치를 불러옴
    let newZIndex: number = getMaxZIndex(selectedLayer);

    // 선택한 레이어를 불러옴
    let newLayer = _.cloneDeep(_.find(layer, { id: selectedLayer })) as ILayer;

    // 선택한 레이어가 최상단의 레이어가 아니라면 선택한 레이어를 최상단으로 변경함
    if (newLayer.zIndex !== newZIndex) {
      // 최상단의 레이어보다 +1 높은 위치로 이동함
      newLayer.zIndex = newZIndex;

      // 최소화한 상태라면 원래대로 복원함
      if (newLayer.minimized) {
        newLayer.minimized = false;
      }

      // 레이어를 수정함
      setModifyLayer(newLayer);
    }
  }, [selectedLayer]);

  // 레이어가 최소화됨
  useEffect(() => {
    if (minimizedLayer === null) {
      return;
    }

    // 선택한 레이어를 불러옴
    let newLayer = _.cloneDeep(_.find(layer, { id: minimizedLayer })) as ILayer;

    // 레이어를 수정함
    newLayer.minimized = true;
    setModifyLayer(newLayer);

    // 이 이벤트를 초기화함
    setMinimizedLayer(null);
  }, [minimizedLayer]);

  // 레이어가 최대화됨
  useEffect(() => {
    if (maximizedLayer === null) {
      return;
    }

    // 바탕화면 개체를 불러옴
    let desktop = document.querySelector('#desktop') as HTMLDivElement;

    // 선택한 레이어를 불러옴
    let newLayer = _.cloneDeep(_.find(layer, { id: maximizedLayer })) as ILayer;

    // 레이어를 수정함
    newLayer.latestSize = newLayer.size;
    newLayer.latestPosition = newLayer.position;
    newLayer.size = {
      width: desktop?.clientWidth || 0,
      height: desktop?.clientHeight || 0,
    };
    newLayer.position = { x: 0, y: 0 };
    newLayer.maximized = true;
    setModifyLayer(newLayer);

    // 이 이벤트를 초기화함
    setMaximizedLayer(null);
  }, [maximizedLayer]);

  // 레이어가 원래 크기 및 위치로 복원됨
  useEffect(() => {
    if (restoreLayer === null) {
      return;
    }

    // 선택한 레이어를 불러옴
    let newLayer = _.cloneDeep(_.find(layer, { id: restoreLayer })) as ILayer;

    // 레이어를 수정함
    newLayer.size = newLayer.latestSize;
    newLayer.position = newLayer.latestPosition;
    newLayer.maximized = false;
    newLayer.splitWay = null;
    setModifyLayer(newLayer);

    // 이 이벤트를 초기화함
    setRestoreLayer(null);
  }, [restoreLayer]);

  return (
    <div className="relative pointer-events-auto z-30">
      {/* 생성한 레이어를 화면에 출력함 */}
      {layer.map((item: ILayer) => (
        <LayerBase
          key={item.id}
          id={item.id}
          title={item.title}
          icon={item.icon}
          size={{
            width: item.size!.width,
            height: item.size!.height,
          }}
          minSize={{
            width: item.minSize!.width,
            height: item.minSize!.height,
          }}
          maxSize={{
            width: item.maxSize!.width,
            height: item.maxSize!.height,
          }}
          latestSize={item.latestSize}
          position={{
            x: item.position!.x,
            y: item.position!.y,
          }}
          latestPosition={item.position}
          minimized={item.minimized}
          maximized={item.maximized}
          splitWay={item.splitWay}
          showMinimizeButton={item.showMinimizeButton}
          showMaximizeButton={item.showMaximizeButton}
          showCloseButton={item.showCloseButton}
          resizing={item.resizing}
          lockPosition={item.lockPosition}
          doubleClickToMaximize={item.doubleClickToMaximize}
          zIndex={item.zIndex}
        >
          {item.children}
        </LayerBase>
      ))}
    </div>
  );
};

export default Layer;
