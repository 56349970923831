import React, {
  createElement,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { useResizeObserver } from '@mantine/hooks';
import { ILayer } from '../../interfaces/app.interface';
import {
  addLayerStore,
  layerListPopupStore,
  layerStore,
  modifyLayerStore,
  moveToSelectLayerItemPageStore,
  removeLayerStore,
  restoreLayerStore,
  selectedLayerStore,
} from '../../stores/layer.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { menuStore } from '../../stores/menu.store';
import { v4 as uuidv4 } from 'uuid';
import * as componentUtil from '../../utils/component.util';

interface ILayoutProps {
  wallpaperType?: 'color' | 'image';
  wallpaperSource?: string;
}

/**
 * 작업표시줄
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const TaskBar = ({}: PropsWithChildren<ILayoutProps>) => {
  // 레이어 아이템의 가로 크기를 정의함
  let layerItemWidth: number = 256;

  // 작업줄에 표시될 레이어 아이템의 최대 개수를 정의함
  let maxLayerItemPerOneLine: number = 0;

  // 작업줄에 표시될 레이어 아이템의 전체 페이지 수를 정의함
  let maxLayerItemPage: number = 0;

  // 생성한 레이어를 정의함
  const [layer, setLayer] = useRecoilState<ILayer[]>(layerStore);

  // 추가할 레이어를 정의함
  const [addLayer, setAddLayer] = useRecoilState<ILayer | null>(addLayerStore);

  // 수정할 레이어를 정의함
  const [modifyLayer, setModifyLayer] = useRecoilState<ILayer | null>(
    modifyLayerStore,
  );

  // 삭제할 레이어를 정의함
  const [removeLayer, setRemoveLayer] = useRecoilState<string | null>(
    removeLayerStore,
  );

  // 선택한 레이어를 정의함
  const [selectedLayer, setSelectedLayer] = useRecoilState<string | null>(
    selectedLayerStore,
  );

  // 원래 크기 및 위치로 복원한 레이어를 정의함
  const [restoreLayer, setRestoreLayer] = useRecoilState<string | null>(
    restoreLayerStore,
  );

  // 선택한 레이어의 레이어 아이템 페이지로 이동을 정의함
  const [moveToSelectLayerItemPage, setMoveToSelectLayerItemPage] =
    useRecoilState<boolean>(moveToSelectLayerItemPageStore);

  // 메뉴 출력을 정의함
  const [menu, setMenu] = useRecoilState<boolean>(menuStore);

  // 생성한 레이어 목록 팝업을 정의함
  const [layerListPopup, setLayerListPopup] =
    useRecoilState<boolean>(layerListPopupStore);

  // 레이어 아이템을 정의함
  const [layerItem, setLayerItem] = useState<ILayer[]>([]);

  // 작업줄에 표시될 레이어 아이템의 현재 페이지를 정의함
  const [currentLayerItemPage, setCurrentLayerItemPage] = useState<number>(0);

  // 레이어 목록 영역을 정의함
  const [layerItemRef, layerItemRect] = useResizeObserver();

  // 선택한 페이지 번호에 해당하는 레이어 아이템을 불러옴
  const getLayerItems = (page: number) => {
    // 레이어를 불러옴
    let tmplayer: ILayer[] = _.cloneDeep(
      _.slice(
        layer,
        page * maxLayerItemPerOneLine,
        page * maxLayerItemPerOneLine + maxLayerItemPerOneLine,
      ),
    );

    // 레이어 아이템을 기억함
    setLayerItem(tmplayer);
  };

  // 최상위 레이어가 포함되어 있는 페이지 번호를 불러옴
  const getMaxZIndexLayerItemPage = (): number => {
    // 최상단의 레이어를 불러옴
    let maxZIndexLayer = _.maxBy(layer, 'zIndex') as ILayer;

    // 레이어가 하나도 없을 경우에는 기본값을 적용함
    if (maxZIndexLayer === undefined) {
      return 1;
    }

    // 최상단 레이어의 순서를 불러옴
    let maxZIndexLayerIndex: number = _.findIndex(layer, {
      id: maxZIndexLayer.id,
    });

    if (maxZIndexLayerIndex === 0 && maxLayerItemPerOneLine === 0) {
      return 0;
    }

    // 최상단 레이어가 포함된 페이지 번호를 불러옴
    let maxZIndexLayerPage: number = Math.floor(
      maxZIndexLayerIndex / maxLayerItemPerOneLine,
    );

    return maxZIndexLayerPage;
  };

  // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
  const displayLayerItem = (page: number | null = null) => {
    // 작업줄에 표시될 레이어 아이템의 최대 갯수를 계산함
    maxLayerItemPerOneLine = Math.floor(layerItemRect.width / layerItemWidth);

    // 작업줄에 표시될 레이어 아이템의 전체 페이지 수를 계산함
    maxLayerItemPage = Math.ceil(layer.length / maxLayerItemPerOneLine);

    // 이동할 페이지를 정의함
    let moveToPage: number = 0;

    // 페이지가 지정되어 있지 않으면 최상위 레이어가 포함되어 있는 페이지를 불러옴
    if (page === null) {
      // 최상위 레이어가 포함되어 있는 페이지 번호를 불러옴
      moveToPage = getMaxZIndexLayerItemPage();
    } else {
      moveToPage = page;

      // 최소 페이지보다 작으면 기본값을 적용함
      if (moveToPage < 0) {
        moveToPage = 0;
      }

      // 최대 페이지보다 작으면 기본값을 적용함
      if (moveToPage >= maxLayerItemPage) {
        moveToPage = maxLayerItemPage - 1;
      }
    }

    // 선택한 페이지 번호에 해당하는 레이어 아이템을 불러옴
    getLayerItems(moveToPage);

    // 작업줄에 표시될 레이어 아이템의 현재 페이지를 기억함
    setCurrentLayerItemPage(moveToPage);
  };

  // 레이어를 선택함
  const handleLayerItem_onClick = (id: string) => {
    // 선택한 레이어를 기억함
    setSelectedLayer(id);

    // 생성한 레이어 목록 팝업을 닫음
    setLayerListPopup(false);
  };

  // 레이어 아이템의 이전 페이지로 이동함
  const handleLayerItemPrePage_onClick = () => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem(currentLayerItemPage - 1);
  };

  // 레이어 아이템의 다음 페이지로 이동함
  const handleLayerItemNextPage_onClick = () => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem(currentLayerItemPage + 1);
  };

  // 메뉴 출력을 토글함
  const handleMenu_onClick = () => {
    // 메뉴 출력을 토글함
    setMenu((pre: boolean) => !pre);
  };

  // 생성한 레이어 목록 팝업의 출력을 토글함
  const handleLayerListPopup_onClick = () => {
    // 생성한 레이어 목록 팝업의 출력을 토글함
    setLayerListPopup((pre: boolean) => !pre);
  };

  // 컴포넌트를 불러옴
  const loadComponent = (item: string) => {
    let tmpLayer: ILayer | null = null;

    switch (item) {
      // Memo
      case 'memo':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'note-sticky'],
          title: 'Memo',
          size: {
            width: 300,
            height: 120,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['memo'], {}),
        };
        break;

      // Message
      case 'message':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'comment'],
          title: 'Message',
          size: {
            width: 300,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['message'], {}),
        };
        break;

      default:
        break;
    }

    if (tmpLayer !== null) {
      // 레이어를 추가함
      setAddLayer(tmpLayer);
    }
  };

  useEffect(() => {}, []);

  // 레이어가 갱신됨
  useEffect(() => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem();
  }, [layer]);

  // 선택한 레이어, 레이어 목록 영역이 변경될 때마다 실행함
  useEffect(() => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem();
  }, [layerItemRect]);

  // 선택한 레이어의 레이어 아이템 페이지로 이동이 변경될 때마다 실행함
  useEffect(() => {
    if (!moveToSelectLayerItemPage) {
      return;
    }

    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem();

    // 선택한 레이어의 레이어 아이템 페이지로 이동을 적용함
    setMoveToSelectLayerItemPage(false);
  }, [moveToSelectLayerItemPage]);

  return (
    <div className="taskbar relative w-screen h-12 px-3 py-1.5 flex justify-start items-center bg-gray-300 space-x-2 pointer-events-auto select-none">
      {/* 시작 메뉴 */}
      <div
        onClick={handleMenu_onClick}
        className="button-event flex-none h-full px-2 flex justify-center items-center space-x-2"
      >
        {/* 아이콘 */}
        <div className="flex justify-center items-center">
          <FontAwesomeIcon
            icon={['fas', 'splotch']}
            className="w-5 h-5 text-indigo-700"
          />
        </div>

        {/* 제목 */}
        <span className="text-sm font-bold text-gray-600 truncate">MENU</span>
      </div>

      {/* 단축 아이콘 */}
      <div className="flex justify-center items-center space-x-3">
        {/* 구분선 */}
        <div className="pr-1 flex justify-center items-center">
          <div className="w-px h-6 border-l border-gray-400"></div>
          <div className="w-px h-6 border-l border-gray-200"></div>
        </div>

        {/* Memo */}
        <Tooltip title="Memo">
          <div
            onClick={() => loadComponent('memo')}
            className="button-event flex-none h-full flex justify-center items-center"
          >
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fal', 'note-sticky']}
                className="w-5 h-5 text-cyan-700"
              />
            </div>
          </div>
        </Tooltip>

        {/* Message */}
        <Tooltip title="Message">
          <div
            onClick={() => loadComponent('message')}
            className="button-event flex-none h-full flex justify-center items-center"
          >
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fal', 'comment']}
                className="w-5 h-5 text-cyan-700"
              />
            </div>
          </div>
        </Tooltip>

        {/* Notification History */}
        <Tooltip title="Notification History">
          <div className="button-event flex-none h-full flex justify-center items-center">
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fal', 'rectangle-history']}
                className="w-5 h-5 text-cyan-700"
              />
            </div>
          </div>
        </Tooltip>

        {/* File Storage */}
        <Tooltip title="File Storage">
          <div className="button-event flex-none h-full flex justify-center items-center">
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fal', 'folder']}
                className="w-5 h-5 text-cyan-700"
              />
            </div>
          </div>
        </Tooltip>
      </div>

      {/* 생성한 레이어 목록 */}
      <div
        ref={layerItemRef}
        className="taskbar grow h-full flex justify-start items-center px-2 space-x-1.5 pointer-events-auto overflow-hidden"
      >
        {layerItem.map((item: ILayer) => (
          <div
            key={item.id}
            onClick={() => handleLayerItem_onClick(item.id!)}
            className="button-event w-64 h-full"
          >
            {/* 선택한 레이어가 아닐 경우 */}
            {item.id !== selectedLayer && (
              <div className="layer-item w-full h-full px-2.5 flex justify-start items-center bg-gray-100 space-x-2 rounded">
                {/* 아이콘 */}
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={item.icon!}
                    className="w-5 h-5 text-amber-400"
                  />
                </div>

                {/* 제목 */}
                <span className="text-xs font-medium text-gray-700 truncate">
                  {item.title}
                </span>
              </div>
            )}

            {/* 선택한 레이어일 경우 */}
            {item.id === selectedLayer && (
              <div className="selected-layer-item w-full h-full px-2.5 flex justify-start items-center bg-gray-700 space-x-2 rounded">
                {/* 아이콘 */}
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={item.icon!}
                    className="w-5 h-5 text-indigo-300"
                  />
                </div>

                {/* 제목 */}
                <span className="text-xs font-semibold text-gray-200 truncate">
                  {item.title}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* 버튼 */}
      <div className="flex-none h-full flex justify-center items-center space-x-2">
        {/* 레이어 목록 이동 버튼 */}
        <div className="h-full flex justify-center items-center">
          {/* 이전 */}
          <div
            onClick={handleLayerItemPrePage_onClick}
            className="button-event h-full px-2 flex justify-center items-center"
          >
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fal', 'angle-left']}
                className="w-5 h-5 text-gray-600"
              />
            </div>
          </div>

          {/* 다음 */}
          <div
            onClick={handleLayerItemNextPage_onClick}
            className="button-event h-full px-2 flex justify-center items-center"
          >
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fal', 'angle-right']}
                className="w-5 h-5 text-gray-600"
              />
            </div>
          </div>
        </div>

        {/* 기타 버튼 */}
        <div className="h-full flex justify-center items-center">
          {/* 레이어 목록 팝업 */}
          <div
            onClick={handleLayerListPopup_onClick}
            className="button-event h-full px-2 flex justify-center items-center"
          >
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={['fal', 'window-restore']}
                className="w-5 h-5 text-gray-600"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskBar;
