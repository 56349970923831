import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';

interface ILayerProps {}

/**
 * PDF Viewer
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PdfViewer = ({}: PropsWithChildren<ILayerProps>) => {
  const [firstView, setFirstView] = useState<boolean>(false);

  const [pdfFile, setPdfFile] = useState<string>('');

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePreButton_onClick = () => {
    if (pageNumber <= 1) {
      return;
    }

    setPageNumber((pre: number) => pre - 1);
  };

  const handleNextButton_onClick = () => {
    if (pageNumber >= numPages!) {
      return;
    }

    setPageNumber((pre: number) => pre + 1);
  };

  const handleItem_onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleItem_onDrop = useCallback((event: any) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/dragItem');

    if (typeof type === 'undefined' || !type) {
      return;
    }

    setNumPages(1);
    setPageNumber(1);

    setPdfFile(type);
  }, []);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'https://layer.easysend.co.kr/pdf.worker.min.js',
      import.meta.url,
    ).toString();
    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //   'pdfjs-dist/build/pdf.worker.min.js',
    //   import.meta.url,
    // ).toString();

    console.log('> import.meta.url:', import.meta.url);

    setTimeout(() => {
      setFirstView(true);
    }, 500);

    return () => {};
  }, []);

  return (
    <div
      onDragOver={handleItem_onDragOver}
      onDrop={handleItem_onDrop}
      className="bg-white w-full h-full"
    >
      {firstView === true && pdfFile !== '' && (
        <Document
          // file="/pdfs/Office-365-Intro.-and-Tutorials.pdf"
          file={`/pdfs/${pdfFile}`}
          onLoadSuccess={onDocumentLoadSuccess}
          onDragOver={handleItem_onDragOver}
          onDrop={handleItem_onDrop}
          className=""
        >
          <Page pageNumber={pageNumber} />
        </Document>
      )}

      <div className="absolute left-5 top-1/2 -translate-y-1/2">
        {/* 아이콘 */}
        <div
          onClick={handlePreButton_onClick}
          className="button-event w-10 h-10 flex justify-center items-center bg-white border border-gray-400 rounded-full"
        >
          <FontAwesomeIcon
            icon={['far', 'chevron-left']}
            className="w-6 h-6 text-gray-500"
          />
        </div>
      </div>

      <div className="absolute right-5 top-1/2 -translate-y-1/2">
        {/* 아이콘 */}
        <div
          onClick={handleNextButton_onClick}
          className="button-event w-10 h-10 flex justify-center items-center bg-white border border-gray-400 rounded-full"
        >
          <FontAwesomeIcon
            icon={['far', 'chevron-right']}
            className="w-6 h-6 text-gray-500"
          />
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
