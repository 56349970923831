import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useClickOutside, useResizeObserver } from '@mantine/hooks';
import { ILayer } from '../../interfaces/app.interface';
import {
  addLayerStore,
  layerListPopupStore,
  layerStore,
  modifyLayerStore,
  moveToSelectLayerItemPageStore,
  removeLayerStore,
  selectedLayerStore,
} from '../../stores/layer.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { ScrollArea } from '@mantine/core';
import { motion } from 'framer-motion';

interface ILayoutProps {
  wallpaperType?: 'color' | 'image';
  wallpaperSource?: string;
}

/**
 * 작업표시줄 레이어 목록
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const LayerList = ({}: PropsWithChildren<ILayoutProps>) => {
  // 개체 밖의 클릭 이벤트를 정의함
  const ref = useClickOutside(() => setLayerListPopup(false));

  // 레이어 아이템의 가로 크기를 정의함
  let layerItemWidth: number = 256;

  // 작업줄에 표시될 레이어 아이템의 최대 개수를 정의함
  let maxLayerItemPerOneLine: number = 0;

  // 작업줄에 표시될 레이어 아이템의 전체 페이지 수를 정의함
  let maxLayerItemPage: number = 0;

  // 생성한 레이어를 정의함
  const [layer, setLayer] = useRecoilState<ILayer[]>(layerStore);

  // 추가할 레이어를 정의함
  const [addLayer, setAddLayer] = useRecoilState<ILayer | null>(addLayerStore);

  // 수정할 레이어를 정의함
  const [modifyLayer, setModifyLayer] = useRecoilState<ILayer | null>(
    modifyLayerStore,
  );

  // 삭제할 레이어를 정의함
  const [removeLayer, setRemoveLayer] = useRecoilState<string | null>(
    removeLayerStore,
  );

  // 선택한 레이어를 정의함
  const [selectedLayer, setSelectedLayer] = useRecoilState<string | null>(
    selectedLayerStore,
  );

  // 선택한 레이어의 레이어 아이템 페이지로 이동을 정의함
  const [moveToSelectLayerItemPage, setMoveToSelectLayerItemPage] =
    useRecoilState<boolean>(moveToSelectLayerItemPageStore);

  // 생성한 레이어 목록 팝업을 정의함
  const [layerListPopup, setLayerListPopup] =
    useRecoilState<boolean>(layerListPopupStore);

  // 레이어 아이템을 정의함
  const [layerItem, setLayerItem] = useState<ILayer[]>([]);

  // 작업줄에 표시될 레이어 아이템의 현재 페이지를 정의함
  const [currentLayerItemPage, setCurrentLayerItemPage] = useState<number>(0);

  // 레이어 목록 영역을 정의함
  const [layerItemRef, layerItemRect] = useResizeObserver();

  // 화면 전체 크기 영역을 정의함
  const [screenRef, screenRect] = useResizeObserver();

  // 레이어 목록의 세로 크기를 정의함
  const [layerListHeight, setLayerListHeight] = useState<number>(0);

  // 선택한 페이지 번호에 해당하는 레이어 아이템을 불러옴
  const getLayerItems = (page: number) => {
    // 레이어를 불러옴
    let tmplayer: ILayer[] = _.cloneDeep(
      _.slice(
        layer,
        page * maxLayerItemPerOneLine,
        page * maxLayerItemPerOneLine + maxLayerItemPerOneLine,
      ),
    );

    // 레이어 아이템을 기억함
    setLayerItem(tmplayer);
  };

  // 최상위 레이어가 포함되어 있는 페이지 번호를 불러옴
  const getMaxZIndexLayerItemPage = (): number => {
    // 최상단의 레이어를 불러옴
    let maxZIndexLayer = _.maxBy(layer, 'zIndex') as ILayer;

    // 레이어가 하나도 없을 경우에는 기본값을 적용함
    if (maxZIndexLayer === undefined) {
      return 1;
    }

    // 최상단 레이어의 순서를 불러옴
    let maxZIndexLayerIndex: number = _.findIndex(layer, {
      id: maxZIndexLayer.id,
    });

    if (maxZIndexLayerIndex === 0 && maxLayerItemPerOneLine === 0) {
      return 0;
    }

    // 최상단 레이어가 포함된 페이지 번호를 불러옴
    let maxZIndexLayerPage: number = Math.floor(
      maxZIndexLayerIndex / maxLayerItemPerOneLine,
    );

    return maxZIndexLayerPage;
  };

  // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
  const displayLayerItem = (page: number | null = null) => {
    // 작업줄에 표시될 레이어 아이템의 최대 갯수를 계산함
    maxLayerItemPerOneLine = Math.floor(layerItemRect.width / layerItemWidth);

    // 작업줄에 표시될 레이어 아이템의 전체 페이지 수를 계산함
    maxLayerItemPage = Math.ceil(layer.length / maxLayerItemPerOneLine);

    // 이동할 페이지를 정의함
    let moveToPage: number = 0;

    // 페이지가 지정되어 있지 않으면 최상위 레이어가 포함되어 있는 페이지를 불러옴
    if (page === null) {
      // 최상위 레이어가 포함되어 있는 페이지 번호를 불러옴
      moveToPage = getMaxZIndexLayerItemPage();
    } else {
      moveToPage = page;

      // 최소 페이지보다 작으면 기본값을 적용함
      if (moveToPage < 0) {
        moveToPage = 0;
      }

      // 최대 페이지보다 작으면 기본값을 적용함
      if (moveToPage >= maxLayerItemPage) {
        moveToPage = maxLayerItemPage - 1;
      }
    }

    // 선택한 페이지 번호에 해당하는 레이어 아이템을 불러옴
    getLayerItems(moveToPage);

    // 작업줄에 표시될 레이어 아이템의 현재 페이지를 기억함
    setCurrentLayerItemPage(moveToPage);
  };

  // 레이어 목록의 세로 크기를 변경함
  const updateLayerListHeight = () => {
    // 바탕화면 개체를 불러옴
    let desktop = document.querySelector('#desktop') as HTMLDivElement;

    let screenSize: { width: number; height: number } = {
      width: desktop?.clientWidth || 0,
      height: desktop?.clientHeight || 0,
    };

    let height: number = 40 * layer.length + 2 * (layer.length - 1);

    // 화면의 세로 크기보다 크지 않도록 보정값을 적용함
    if (height > screenSize.height - 300) {
      height = screenSize.height - 300;
    }

    // 레이어 목록의 세로 크기를 적용함
    setLayerListHeight(height);
  };

  // 레이어를 선택함
  const handleLayerItem_onClick = (id: string) => {
    // 선택한 레이어를 기억함
    setSelectedLayer(id);

    // // 생성한 레이어 목록 팝업을 닫음
    // setLayerListPopup(false);
  };

  // 레이어 아이템의 이전 페이지로 이동함
  const handleLayerItemPrePage_onClick = () => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem(currentLayerItemPage - 1);
  };

  // 레이어 아이템의 다음 페이지로 이동함
  const handleLayerItemNextPage_onClick = () => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem(currentLayerItemPage + 1);
  };

  // 생성한 레이어 목록 팝업의 출력을 토글함
  const handleLayerListPopup_onClick = () => {
    // 생성한 레이어 목록 팝업의 출력을 토글함
    setLayerListPopup(!layerListPopup);
  };

  // 이 컴포넌트를 종료함
  const handleCloseComponent_onClick = () => {
    // 생성한 레이어 목록 팝업을 닫음
    setLayerListPopup(false);
  };

  useEffect(() => {}, []);

  // 레이어가 갱신됨
  useEffect(() => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem();
  }, [layer]);

  // 선택한 레이어, 레이어 목록 영역이 변경될 때마다 실행함
  useEffect(() => {
    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem();
  }, [layerItemRect]);

  // 선택한 레이어의 레이어 아이템 페이지로 이동이 변경될 때마다 실행함
  useEffect(() => {
    if (!moveToSelectLayerItemPage) {
      return;
    }

    // 작업줄에 표시될 레이어 아이템의 개수를 계산하여 레이어 아이템 페이지를 출력함
    displayLayerItem();

    // 선택한 레이어의 레이어 아이템 페이지로 이동을 적용함
    setMoveToSelectLayerItemPage(false);
  }, [moveToSelectLayerItemPage]);

  // 화면 전체 크기 영역이 변경될 때마다 실행함
  useEffect(() => {
    // 레이어 목록의 세로 크기를 변경함
    updateLayerListHeight();
  }, [screenRect, layerListPopup]);

  return (
    <motion.div
      ref={ref}
      initial="close"
      animate={layerListPopup ? 'open' : 'close'}
      variants={{
        open: {
          display: 'block',
          opacity: 1,
          x: 0,
          y: 0,
          transition: {
            type: 'spring',
            // bounce: 0,
            duration: 0.3,
          },
        },
        close: {
          opacity: 0,
          x: 1000,
          y: 0,
          transition: {
            type: 'spring',
            // bounce: 0,
            duration: 0.3,
          },
          // transitionEnd: { display: 'none' },
        },
      }}
      className="absolute right-5 bottom-5 w-88 p-2 bg-gray-300 rounded shadow-md select-none pointer-events-auto z-90"
    >
      <ScrollArea
        style={{
          height: layerListHeight,
        }}
      >
        <div className="space-y-0.5">
          {layer.map((item: ILayer) => (
            <div
              key={item.id}
              onClick={() => handleLayerItem_onClick(item.id!)}
              className="button-event w-full h-10"
            >
              {/* 선택한 레이어가 아닐 경우 */}
              {item.id !== selectedLayer && (
                <div className="layer-item w-full h-full px-2.5 flex justify-start items-center bg-gray-100 space-x-2 rounded">
                  {/* 아이콘 */}
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={item.icon!}
                      className="w-5 h-5 text-amber-400"
                    />
                  </div>

                  {/* 제목 */}
                  <span className="text-xs font-medium text-gray-700 truncate">
                    {item.title}
                  </span>
                </div>
              )}

              {/* 선택한 레이어일 경우 */}
              {item.id === selectedLayer && (
                <div className="selected-layer-item w-full h-full px-2.5 flex justify-start items-center bg-gray-700 space-x-2 rounded">
                  {/* 아이콘 */}
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={item.icon!}
                      className="w-5 h-5 text-indigo-300"
                    />
                  </div>

                  {/* 제목 */}
                  <span className="text-xs font-semibold text-gray-200 truncate">
                    {item.title}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </ScrollArea>
    </motion.div>
  );
};

export default LayerList;
