import React, { PropsWithChildren, useEffect, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import '../../styles/excel.style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from 'antd';
import { useElementSize } from '@mantine/hooks';
import { ScrollArea } from '@mantine/core';
import { useRecoilState } from 'recoil';
import { IConfig } from '../../interfaces/app.interface';
import { configStore } from '../../stores/config.store';
import axios from 'axios';

interface ILayerProps {}

/**
 * 환경설정
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Config = ({}: PropsWithChildren<ILayerProps>) => {
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 환경설정 저장소를 정의함
  const [config, setConfig] = useRecoilState<IConfig>(configStore);

  // 선택한 메뉴를 정의함
  const [selectedMenu, setSelectedMenu] = useState<string>('');

  // 바탕화면 > 단축 아이콘의 스위치를 변경함
  const handleShortCut_onChange = (event: any) => {
    // 환경설정 저장소에 적용함
    setConfig((pre: IConfig) => ({
      ...pre,
      enableShortCut: event,
    }));
  };

  useEffect(() => {}, []);

  return (
    <div ref={contentRef} className="bg-white flex w-full h-full">
      {/* 메뉴 */}
      <div className="w-52 h-full pr-1">
        <ScrollArea h={contentHeight}>
          {/* 아이템 */}
          <div
            onClick={() => setSelectedMenu('desktop')}
            className={[
              'button-event px-2 py-1 flex justify-start items-center space-x-2 rounded-sm',
              selectedMenu === 'desktop' && 'bg-slate-200',
            ].join(' ')}
          >
            <div className="p-1 flex justify-center items-center bg-blue-500 rounded">
              <FontAwesomeIcon
                icon={['fas', 'desktop']}
                className="w-3 h-3 text-white"
              />
            </div>

            <div>
              <span className="text-sm font-gray-600 text-bold">바탕화면</span>
            </div>
          </div>

          {/* 아이템 */}
          <div
            onClick={() => setSelectedMenu('system')}
            className={[
              'button-event px-2 py-1 flex justify-start items-center space-x-2 rounded-sm',
              selectedMenu === 'system' && 'bg-slate-200',
            ].join(' ')}
          >
            <div className="p-1 flex justify-center items-center bg-green-500 rounded">
              <FontAwesomeIcon
                icon={['fas', 'power-off']}
                className="w-3 h-3 text-white"
              />
            </div>

            <div>
              <span className="text-sm font-gray-600 text-bold">시스템</span>
            </div>
          </div>

          {/* 분리선 */}
          <div className="my-1 w-full h-px bg-gray-200"></div>
        </ScrollArea>
      </div>

      {/* 내용 */}
      <div className="grow h-full bg-slate-200 rounded-sm">
        <ScrollArea h={contentHeight} className="p-3">
          {/* 바탕화면 */}
          {selectedMenu === 'desktop' && (
            <div className="space-y-3">
              {/* 그룹 */}
              <div>
                {/* 설명 */}
                <div className="px-1">
                  <span className="text-xs text-gray-500 font-semibold">
                    바탕화면의 단축 아이콘을 보이거나 숨깁니다.
                  </span>
                </div>

                {/* 설정 */}
                <div className="w-full bg-white rounded-md">
                  {/* 아이템 */}
                  <div className="px-3 py-2 flex justify-between items-center leading-none">
                    <div>
                      <span className="text-sm text-gray-500 font-semibold">
                        단축 아이콘
                      </span>
                    </div>

                    <div>
                      <Switch
                        defaultChecked
                        onChange={handleShortCut_onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* 그룹 */}
              <div>
                {/* 설명 */}
                <div className="px-1">
                  <span className="text-xs text-gray-500 font-semibold">
                    배경화면의 이미지나 색상을 변경합니다.
                  </span>
                </div>

                {/* 설정 */}
                <div className="w-full bg-white rounded-md">
                  {/* 아이템 */}
                  <div className="px-3 py-2 flex justify-between items-center leading-none">
                    <div>
                      <span className="text-sm text-gray-500 font-semibold">
                        비활성화
                      </span>
                    </div>

                    <div>
                      <Switch
                        defaultChecked
                        // onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  );
};

export default Config;
