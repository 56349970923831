import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import {
  moveToSelectLayerItemPageStore,
  selectedLayerStore,
} from '../../stores/layer.store';

interface ILayerProps {
  layerId: string;
}

/**
 * 외부 앱
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ExtraApp = ({ layerId }: PropsWithChildren<ILayerProps>) => {
  // 선택한 레이어를 정의함
  const [selectedLayer, setSelectedLayer] = useRecoilState<string | null>(
    selectedLayerStore,
  );

  // 선택한 레이어의 레이어 아이템 페이지로 이동을 정의함
  const [moveToSelectLayerItemPage, setMoveToSelectLayerItemPage] =
    useRecoilState<boolean>(moveToSelectLayerItemPageStore);

  // iframe의 참조를 정의함
  const iframeRef = useRef<any>(null);

  // iframe을 클릭하면 창을 맨 앞으로 가져옴
  const handleIframe_onBlur = () => {
    if (document.activeElement === iframeRef.current) {
      // // 선택한 레이어를 기억함
      // setSelectedLayer(layerId);
      //
      // // 선택한 레이어의 레이어 아이템 페이지로 이동을 적용함
      // setMoveToSelectLayerItemPage(true);
    }
  };

  useEffect(() => {
    window.addEventListener('blur', handleIframe_onBlur);

    return () => {
      window.removeEventListener('blur', handleIframe_onBlur);
    };
  }, []);

  return (
    <div className="relative bg-white w-full h-full">
      <iframe
        ref={iframeRef}
        src="http://localhost:8080"
        width="100%"
        height="100%"
        onMouseOver={() => {
          (document.activeElement as HTMLElement).blur();
        }}
      />
    </div>
  );
};

export default ExtraApp;
