import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Input } from 'antd';
import Joi from 'joi';

const { TextArea } = Input;

interface ILayerProps {}

/**
 * 유효성 검사
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Validator = ({}: PropsWithChildren<ILayerProps>) => {
  // 사용자 아이디, 이름, 나이를 정의함
  const [userId, setUserId] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userAge, setUserAge] = useState<number>(0);

  // 유효성 검사 형식을 정의함
  const userSchema = Joi.object({
    userId: Joi.string()
      .min(3)
      .pattern(new RegExp(/^[a-z|A-Z|0-9]+$/))
      .required()
      .messages({
        'string.empty': '사용자 아이디를 입력하세요.',
        'string.min': '사용자 이름은 최소 3글자 이상입니다.',
        'string.pattern.base':
          '사용자 아이디는 알파벳 대/소문자, 숫자만 가능합니다.',
      }),
    userName: Joi.string()
      .min(2)
      .max(10)
      .pattern(new RegExp(/^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]+$/))
      .required()
      .messages({
        'string.empty': '사용자 이름을 입력하세요.',
        'string.min': '사용자 이름은 최소 2글자 이상입니다.',
        'string.max': '사용자 이름은 최대 10글자까지 입니다.',
        'string.pattern.base':
          '사용자 이름은 한글, 알파벳 대/소문자, 숫자만 가능합니다.',
      }),
    userAge: Joi.number().min(5).messages({
      'number.empty': '사용자 나이를 입력하세요.',
      'number.min': '사용자 나이는 최소 5 이상입니다.',
    }),
  });

  const handleCheckButton_onClick = () => {
    // 입력 항목의 유효성을 검사함
    const data = userSchema.validate({
      userId: userId,
      userName: userName,
      userAge: +userAge,
    });

    // 유효성 검사가 모두 통과되면 오류가 undefined로 반환됨
    if (data.error === undefined) {
      console.log('Complete');
    } else {
      console.log('Error:', data.error.message);
      return;
    }
  };

  return (
    <div className="bg-white w-full h-full">
      <div className="px-1 space-y-3">
        {/* 사용자 아이디 */}
        <div className="space-y-0.5">
          <div className="px-1 flex justify-start items-center leading-none">
            <span className="text-xs text-gray-600 font-semibold">User ID</span>
          </div>
          <div className="flex justify-center items-center">
            <Input
              onChange={(event: any) => setUserId(event.target.value)}
              value={userId}
              className="w-full !h-full"
            />
          </div>
        </div>

        {/* 사용자 이름 */}
        <div className="space-y-0.5">
          <div className="px-1 flex justify-start items-center leading-none">
            <span className="text-xs text-gray-600 font-semibold">
              User Name
            </span>
          </div>
          <div className="flex justify-center items-center">
            <Input
              onChange={(event: any) => setUserName(event.target.value)}
              value={userName}
              className="w-full !h-full"
            />
          </div>
        </div>

        {/* 사용자 나이 */}
        <div className="space-y-0.5">
          <div className="px-1 flex justify-start items-center leading-none">
            <span className="text-xs text-gray-600 font-semibold">
              User Age
            </span>
          </div>
          <div className="flex justify-center items-center">
            <Input
              onChange={(event: any) => setUserAge(event.target.value)}
              value={userAge}
              className="w-full !h-full"
            />
          </div>
        </div>

        {/* 버튼 */}
        <div className="flex justify-center items-center">
          <div
            onClick={handleCheckButton_onClick}
            className="button-event px-5 py-2 flex justify-center items-center bg-blue-600 rounded"
          >
            <span className="text-sm font-semibold text-white">Check</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Validator;
