import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Layer } from '../../managers/Layer';
import { ILayer } from '../../interfaces/app.interface';
import {
  addLayerStore,
  draggingLayerStore,
  splitDraggingLayerStore,
} from '../../stores/layer.store';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import SplitLayerArea from '../SplitLayerArea/SplitLayerArea.layout';
import { useMouse } from '@mantine/hooks';
import { Menu } from '../Menu';
import { LayerList } from '../LayerList';
import { ShortCut } from '../ShortCut';

interface ILayoutProps {}

/**
 * 바탕화면
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Desktop = ({}: PropsWithChildren<ILayoutProps>) => {
  // 마우스 이벤트를 정의함
  const { ref: mouseRef, x: mouseX, y: mouseY } = useMouse();

  // 추가할 레이어 저장소를 정의함
  const [addLayer, setAddLayer] = useRecoilState<ILayer | null>(addLayerStore);

  // 드래그 중인 레이어 저장소를 정의함
  const [draggingLayer, setDraggingLayer] = useRecoilState<string | null>(
    draggingLayerStore,
  );

  // 드래그 중인 레이어를 화면 양쪽 끝으로 이동 저장소를 정의함
  const [splitDraggingLayer, setSplitDraggingLayer] = useRecoilState<
    'left' | 'right' | null
  >(splitDraggingLayerStore);

  // 임시!!
  // 버튼을 클릭함
  const handleAddButton_onClick = () => {
    let tmpLayer: ILayer = {
      id: uuidv4(),
      icon: ['fas', 'star'],
      // title: moment(new Date()).format('YYYY.MM.DD 레이어'),
      title: uuidv4(),
      size: {
        width: 400,
        height: 300,
      },
      minSize: {
        width: 300,
        height: 44,
      },
      maxSize: {
        width: 0,
        height: 0,
      },
      // position: {
      //   x: 1600,
      //   y: 1000,
      // },
      minimized: false,
      maximized: false,
      showMinimizeButton: true,
      showMaximizeButton: true,
      showCloseButton: true,
      resizing: true,
      lockPosition: false,
      doubleClickToMaximize: true,
      // zIndex: 1,
      children: <div>테스트</div>,
    };

    // 레이어를 추가함
    setAddLayer(tmpLayer);
  };

  useEffect(() => {
    return () => {};
  }, []);

  // 드래그 중인 레이어가 있을 때, 바탕화면의 양쪽 끝 영역을 인식함
  useEffect(() => {
    if (draggingLayer === null) {
      return;
    }

    // 바탕화면 개체를 불러옴
    let desktop = document.querySelector('#desktop') as HTMLDivElement;

    if (mouseX < 30) {
      // 왼쪽 영역을 인식함
      if (splitDraggingLayer !== 'left') {
        // 드래그 중인 레이어를 화면 양쪽 끝으로 이동 저장소에 적용함
        setSplitDraggingLayer('left');
      }
    } else if (desktop?.clientWidth - 30 < mouseX) {
      // 오른쪽 영역을 인식함
      if (splitDraggingLayer !== 'right') {
        // 드래그 중인 레이어를 화면 양쪽 끝으로 이동 저장소에 적용함
        setSplitDraggingLayer('right');
      }
    } else {
      // 양쪽 영역을 초기화함
      if (splitDraggingLayer !== null) {
        // 드래그 중인 레이어를 화면 양쪽 끝으로 이동 저장소에 적용함
        setSplitDraggingLayer(null);
      }
    }

    return () => {};
  }, [mouseX]);

  return (
    <div
      ref={mouseRef}
      id="desktop"
      className="relative flex-grow w-full overflow-hidden z-10"
    >
      {/* 레이어 관리자 */}
      <Layer />

      {/* 바탕화면의 분할 레이어 영역 */}
      <SplitLayerArea areaType="left" />

      {/* 메뉴 */}
      <Menu />

      {/* 작업표시줄 레이어 목록 */}
      <LayerList />

      {/* 바탕화면용 단축 아이콘 */}
      <ShortCut />

      {/* 임시!! */}
      {/*<div*/}
      {/*  onClick={handleAddButton_onClick}*/}
      {/*  className="button-event absolute left-5 top-5 z-20"*/}
      {/*>*/}
      {/*  <span className="text-white">추가</span>*/}
      {/*</div>*/}

      {/* 로고 */}
      {/*<div*/}
      {/*  style={{*/}
      {/*    backgroundImage: `url(/images/logo.png)`,*/}
      {/*  }}*/}
      {/*  className="absolute right-7 bottom-5 w-32 h-7 bg-no-repeat bg-contain bg-center z-10"*/}
      {/*></div>*/}

      {/* 제목 */}
      <div className="absolute right-6 bottom-4 z-10 flex justify-center items-center select-none space-x-2">
        <div className="flex justify-center items-center">
          <span className="text-xs text-white/70 font-medium leading-none">
            Layer Desktop Dev. * Tech DEMO
          </span>
        </div>

        <div
          onClick={() => {
            window.open('https://hunizm.com', '_blank');
          }}
          className="button-event px-2 py-1 flex justify-center items-center bg-sky-900 rounded-sm"
        >
          <span className="text-xs text-white font-medium leading-none">
            HUNIZM
          </span>
        </div>
      </div>

      {/*<div className="">111</div>*/}
    </div>
  );
};

export default Desktop;
