import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'reactflow/dist/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Switch } from 'antd';
import { useElementSize } from '@mantine/hooks';
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  DefaultEdgeOptions,
  Edge,
  Handle,
  MiniMap,
  Node,
  NodeProps,
  NodeResizer,
  NodeToolbar,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  Panel,
  Position,
  ProOptions,
  ReactFlowProvider,
  useEdges,
  useEdgesState,
  useNodeId,
  useNodes,
  useNodesState,
  useOnSelectionChange,
  useReactFlow,
} from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import ButtonNode from './Nodes/Button.node';
import ConsoleNode from './Nodes/Console.node';
import TextNode from './Nodes/Text.node';
import RandomNumberNode from './Nodes/RandomNumber.node';
import SliderNumberNode from './Nodes/SliderNumber.node';
import SendWindowMessageNode from './Nodes/SendWindowMessage.node';
import TickNode from './Nodes/Tick.node';
import { motion } from 'framer-motion';

interface ILayerProps {}

/**
 * Process Flow
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ProcessFlow = ({}: PropsWithChildren<ILayerProps>) => {
  const nodeTypes = useMemo(
    () => ({
      textUpdater: TextUpdaterNode,
      sampleNode: SampleNode,
      buttonNode: ButtonNode,
      consoleNode: ConsoleNode,
      textNode: TextNode,
      randomNumberNode: RandomNumberNode,
      sliderNumberNode: SliderNumberNode,
      sendWindowMessageNode: SendWindowMessageNode,
      tickNode: TickNode,
    }),
    [],
  );

  // 왼쪽 드래그 노드 메뉴의 출력을 정의함
  const [toggleSideMenu, setToggleSideMenu] = useState<boolean>(false);

  // 노드 내부의 데이터 흐름을 처리함
  const nodeInternalProcessing = (targetNodeParam: any) => {
    // 노드의 종류대로 처리함
    switch (targetNodeParam.nodeType) {
      // Console Node
      case 'consoleNode':
        // out handle
        nodesRef.current
          .filter(
            (filterItem: Node) => filterItem.id === targetNodeParam.nodeId,
          )
          .map((nodeItem: Node) => {
            nodeItem.data.console = targetNodeParam.data;
          });

        setNodes(_.cloneDeep(nodesRef.current));
        break;

      // Text Node
      case 'textNode':
        let tmpText: string = '';

        // // in handle
        // modifyData([
        //   {
        //     nodeId: targetNodeParam.nodeId,
        //     objectKey: 'text',
        //     value: targetNodeParam.data,
        //   },
        // ]);

        // 이 노드의 데이터를 불러옴
        nodesRef.current
          .filter(
            (filterItem: Node) => filterItem.id === targetNodeParam.nodeId,
          )
          .map((nodeItem: Node) => {
            tmpText = nodeItem.data.text;
          });

        // out handle
        sendData({
          nodeId: targetNodeParam.nodeId,
          handleId: 'out',
          data: tmpText,
        });
        break;

      // Random Number Node
      case 'randomNumberNode':
        let tmpRandomNumber: string = '';

        // 이 노드의 데이터를 불러옴
        nodesRef.current
          .filter(
            (filterItem: Node) => filterItem.id === targetNodeParam.nodeId,
          )
          .map((nodeItem: Node) => {
            tmpRandomNumber = _.random(
              nodeItem.data.minNumber,
              nodeItem.data.maxNumber,
            ).toString();
          });

        // out handle
        sendData({
          nodeId: targetNodeParam.nodeId,
          handleId: 'out',
          data: tmpRandomNumber,
        });
        break;

      // Slider Number Node
      case 'sliderNumberNode':
        let tmpSliderNumber: string = '';

        // 이 노드의 데이터를 불러옴
        nodesRef.current
          .filter(
            (filterItem: Node) => filterItem.id === targetNodeParam.nodeId,
          )
          .map((nodeItem: Node) => {
            tmpSliderNumber = nodeItem.data.sliderNumber.toString();
          });

        // out handle
        sendData({
          nodeId: targetNodeParam.nodeId,
          handleId: 'out',
          data: tmpSliderNumber,
        });
        break;

      // Send Window Message Node
      case 'sendWindowMessageNode':
        let tmpSendWindowMessage: string = '';

        // 이 노드의 데이터를 불러옴
        nodesRef.current
          .filter(
            (filterItem: Node) => filterItem.id === targetNodeParam.nodeId,
          )
          .map((nodeItem: Node) => {
            tmpSendWindowMessage = nodeItem.data.text;
          });

        // 윈도우 메시지를 보냄
        window.postMessage(
          {
            type: 'processFlow',
            id: tmpSendWindowMessage,
            data: targetNodeParam.data,
          },
          '*',
        );
        break;

      default:
        break;
    }
  };

  // 노드에서 받은 데이터를 처리함
  const sendData = (sourceNode: any) => {
    // console.log('> sendData:', sourceNode);
    // console.log('> sendData.2:', nodes);
    // console.log('> sendData.22:', nodesRef.current);
    // console.log('> edges:', edges);

    // 대상 노드에게 데이터를 전달함
    edgesRef.current.map((item: any) => {
      if (
        item.source === sourceNode.nodeId &&
        item.sourceHandle === sourceNode.handleId
      ) {
        let tmpTargetNode: any = reactFlowInstance.getNode(item.target);

        let tmpTargetNodeParam: any = {
          nodeType: tmpTargetNode.type,
          nodeId: item.target,
          handleId: item.targetHandle,
          data: sourceNode.data,
        };

        nodeInternalProcessing(tmpTargetNodeParam);
      }
    });
  };

  // 노드에서 받은 데이터를 수정함
  const modifyData = (nodeParam: any) => {
    nodeParam.map((item: any) => {
      // 이 노드의 데이터를 불러와서 수정함
      nodesRef.current
        .filter((filterItem: Node) => filterItem.id === item.nodeId)
        .map((nodeItem: Node) => {
          nodeItem.data[item.objectKey] = item.value;
        });
    });

    setNodes(_.cloneDeep(nodesRef.current));
  };

  const initialNodes: Node[] = [
    // {
    //   id: '1',
    //   type: 'input',
    //   data: { label: 'Input Node' },
    //   position: { x: 250, y: 25 },
    //   style: { backgroundColor: 'red', color: 'white' },
    // },
    //
    // {
    //   id: '2',
    //   data: { label: <div className="bg-blue-300">Default Node</div> },
    //   position: { x: 100, y: 125 },
    // },
    // {
    //   id: '3',
    //   type: 'output',
    //   data: { label: 'Output Node' },
    //   position: { x: 250, y: 250 },
    // },
    // {
    //   id: '4',
    //   type: 'textUpdater',
    //   data: { value: '123' },
    //   position: { x: 500, y: 250 },
    // },
    {
      id: 'button-1',
      type: 'buttonNode',
      data: {
        label: '시작',
        button: {
          label: 'Click',
        },
        sendData: sendData,
      },
      position: { x: 20, y: 200 },
    },
    {
      id: 'text-1',
      type: 'textNode',
      data: {
        label: '문자열 전달',
        text: '',
        modifyData: modifyData,
      },
      position: { x: 300, y: 0 },
    },
    {
      id: 'console-1',
      type: 'consoleNode',
      data: {
        label: '로그 출력',
        console: '',
      },
      position: { x: 550, y: 0 },
    },

    {
      id: 'random-number-2',
      type: 'randomNumberNode',
      data: {
        label: '난수 전달',
        minNumber: 0,
        maxNumber: 1000,
        modifyData: modifyData,
      },
      position: { x: 300, y: 150 },
    },
    {
      id: 'console-2',
      type: 'consoleNode',
      data: {
        label: '로그 출력',
        console: '',
      },
      position: { x: 550, y: 150 },
    },

    {
      id: 'slider-number-3',
      type: 'sliderNumberNode',
      data: {
        label: '슬라이더 숫자 전달',
        minNumber: 0,
        maxNumber: 10,
        sliderNumber: 0,
        modifyData: modifyData,
        sendData: sendData,
      },
      position: { x: 300, y: 300 },
    },
    {
      id: 'console-3',
      type: 'consoleNode',
      data: {
        label: '로그 출력',
        console: '',
      },
      position: { x: 550, y: 300 },
    },
    {
      id: 'send-window-message-3',
      type: 'sendWindowMessageNode',
      data: {
        label: '윈도우 메시지 전달',
        text: '3D.X',
        modifyData: modifyData,
      },
      position: { x: 550, y: 430 },
    },

    {
      id: 'slider-number-4',
      type: 'sliderNumberNode',
      data: {
        label: '슬라이더 숫자 전달',
        minNumber: 0,
        maxNumber: 10,
        sliderNumber: 0,
        modifyData: modifyData,
        sendData: sendData,
      },
      position: { x: 300, y: 550 },
    },
    {
      id: 'send-window-message-4',
      type: 'sendWindowMessageNode',
      data: {
        label: '윈도우 메시지 전달',
        text: '3D.Y',
        modifyData: modifyData,
      },
      position: { x: 550, y: 550 },
    },

    {
      id: 'slider-number-5',
      type: 'sliderNumberNode',
      data: {
        label: '슬라이더 숫자 전달',
        minNumber: 0,
        maxNumber: 10,
        sliderNumber: 0,
        modifyData: modifyData,
        sendData: sendData,
      },
      position: { x: 300, y: 730 },
    },
    {
      id: 'send-window-message-5',
      type: 'sendWindowMessageNode',
      data: {
        label: '윈도우 메시지 전달',
        text: '3D.Z',
        modifyData: modifyData,
      },
      position: { x: 550, y: 730 },
    },

    // {
    //   id: 'button-6',
    //   type: 'buttonNode',
    //   data: {
    //     label: '간격 시작',
    //     button: {
    //       label: 'Toggle Tick',
    //     },
    //     sendData: sendData,
    //   },
    //   position: { x: 20, y: 400 },
    // },
    // {
    //   id: 'tick-6',
    //   type: 'tickNode',
    //   data: {
    //     label: '간격 신호 발생',
    //     button: {
    //       label: 'Toggle Tick',
    //     },
    //     modifyData: modifyData,
    //     sendData: sendData,
    //   },
    //   position: { x: 20, y: 550 },
    // },
  ];

  const initialEdges: Edge[] = [
    // { id: 'e1-2', source: '1', target: '2', label: '테스트', type: 'step' },
    // {
    //   id: 'e2-3',
    //   source: '2',
    //   target: '3',
    //   animated: true,
    // },
    // {
    //   id: 'e2-4',
    //   source: '4',
    //   sourceHandle: 'a',
    //   target: '3',
    //   style: { stroke: 'red' },
    // },
    // {
    //   id: 'e2-5',
    //   source: '4',
    //   sourceHandle: 'b',
    //   target: '3',
    //   label: '통신',
    //   animated: true,
    // },
    {
      id: uuidv4(),
      source: 'button-1',
      sourceHandle: 'out',
      target: 'text-1',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },
    {
      id: uuidv4(),
      source: 'text-1',
      sourceHandle: 'out',
      target: 'console-1',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },

    {
      id: uuidv4(),
      source: 'button-1',
      sourceHandle: 'out',
      target: 'random-number-2',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },
    {
      id: uuidv4(),
      source: 'random-number-2',
      sourceHandle: 'out',
      target: 'console-2',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },

    {
      id: uuidv4(),
      source: 'button-1',
      sourceHandle: 'out',
      target: 'slider-number-3',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },
    {
      id: uuidv4(),
      source: 'slider-number-3',
      sourceHandle: 'out',
      target: 'console-3',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },
    {
      id: uuidv4(),
      source: 'slider-number-3',
      sourceHandle: 'out',
      target: 'send-window-message-3',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },

    {
      id: uuidv4(),
      source: 'button-1',
      sourceHandle: 'out',
      target: 'slider-number-4',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },
    {
      id: uuidv4(),
      source: 'slider-number-4',
      sourceHandle: 'out',
      target: 'send-window-message-4',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },

    {
      id: uuidv4(),
      source: 'button-1',
      sourceHandle: 'out',
      target: 'slider-number-5',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },
    {
      id: uuidv4(),
      source: 'slider-number-5',
      sourceHandle: 'out',
      target: 'send-window-message-5',
      targetHandle: 'in',
      // label: 'in',
      animated: true,
    },

    // {
    //   id: uuidv4(),
    //   source: 'button-6',
    //   sourceHandle: 'out',
    //   target: 'tick-6',
    //   targetHandle: 'in',
    //   // label: 'in',
    //   animated: true,
    // },
  ];

  // const [initialEdges, setInitialEdges] = useState<any>([
  //   { id: 'e1-2', source: '1', target: '2' },
  //   { id: 'e2-3', source: '2', target: '3', animated: true },
  // ]);

  const nodeColor = (node: any) => {
    switch (node.type) {
      case 'input':
        return '#6ede87';
      case 'output':
        return '#6865A5';
      default:
        return '#ff0072';
    }
  };

  const defaultEdgeOptions: DefaultEdgeOptions = { animated: true };

  // 로고 삭제
  const proOptions: ProOptions = { hideAttribution: true };

  // const [nodes, setNodes] = useState<Node[]>(initialNodes);
  // const [edges, setEdges] = useState<Edge[]>(initialEdges);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const nodesRef = useRef<Node[]>(nodes);
  const edgesRef = useRef<Edge[]>(edges);

  nodesRef.current = nodes;
  edgesRef.current = edges;

  // const onNodesChange: OnNodesChange = useCallback(
  //   (changes: any) => setNodes((nds: any) => applyNodeChanges(changes, nds)),
  //   [setNodes],
  // );

  // const onEdgesChange: OnEdgesChange = useCallback(
  //   (changes: any) => setEdges((eds: any) => applyEdgeChanges(changes, eds)),
  //   [setEdges],
  // );

  const onConnect: OnConnect = useCallback(
    (connection: any) =>
      setEdges((eds: any) => addEdge({ ...connection, animated: true }, eds)),
    [setEdges],
  );

  const reactFlowInstance = useReactFlow();
  const nodeInstance = useNodes();
  const edgeInstance = useEdges();

  const handleAddNode_onClick = useCallback(() => {
    const id = uuidv4();
    const newNode = {
      id,
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: {
        label: `Node ${id}`,
      },
    };

    reactFlowInstance.addNodes(newNode);
  }, []);

  // const {
  //   ref: contentRef,
  //   width: contentWidth,
  //   height: contentHeight,
  // } = useElementSize();

  const [waitDraw, setWaitDraw] = useState<boolean>(false);

  const reactFlowWrapper = useRef<any>(null);

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      let newNode: any = {
        id: uuidv4(),
        type: type,
        position: position,
      };

      switch (type) {
        case 'buttonNode':
          newNode.data = {
            label: 'Button',
            button: {
              label: 'Click',
            },
            sendData: sendData,
          };
          break;

        case 'consoleNode':
          newNode.data = {
            label: 'Console',
            console: '',
          };
          break;

        case 'textNode':
          newNode.data = {
            label: 'Text',
            text: '',
            modifyData: modifyData,
          };
          break;

        case 'randomNumberNode':
          newNode.data = {
            label: 'Random Number',
            minNumber: 0,
            maxNumber: 100,
            modifyData: modifyData,
          };
          break;

        case 'sliderNumberNode':
          newNode.data = {
            label: 'Slider Number',
            minNumber: 0,
            maxNumber: 100,
            sliderNumber: 0,
            modifyData: modifyData,
            sendData: sendData,
          };
          break;

        case 'sendWindowMessageNode':
          newNode.data = {
            label: 'Send Window Message',
            text: '',
            modifyData: modifyData,
          };
          break;

        default:
          break;
      }

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance],
  );

  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  // 현재 선택된 노드와 엣지를 불러옴
  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      // console.log('selected nodes:', nodes);
      // console.log('selected edges:', edges);
    },
  });

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);

    setTimeout(() => {
      setWaitDraw(true);
    }, 300);
  }, []);

  useEffect(() => {
    // console.log('> nodes!!!!:', nodes);

    // nodesRef.current = nodes;
    // nodesRef.current = _.cloneDeep(nodes);

    return () => {};
  }, [nodes]);

  useEffect(() => {
    // edgesRef.current = edges;
    // edgesRef.current = _.cloneDeep(edges);

    return () => {};
  }, [edges]);

  useEffect(() => {
    // console.log('> nodeInstance:', nodeInstance);

    return () => {};
  }, [nodeInstance]);

  useEffect(() => {
    // console.log('> edgeInstance:', edgeInstance);

    return () => {};
  }, [edgeInstance]);

  return (
    <div
      // ref={contentRef}
      ref={reactFlowWrapper}
      className="relative bg-white flex w-full h-full"
    >
      {waitDraw && (
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          // defaultEdgeOptions={defaultEdgeOptions}
          fitView={false}
          proOptions={proOptions}
          // snapToGrid={true}
          // edgeTypes={edgeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          {/*<Panel />*/}
          <NodeToolbar />
          {/*<NodeResizer />*/}
          <Controls />
          <MiniMap nodeColor={nodeColor} zoomable={true} pannable={true} />
          <Background color="#ccc" />

          {/* 패널 */}
          {/*<Panel position="top-left">*/}
          {/*  <div*/}
          {/*    onClick={() => handleAddNode_onClick()}*/}
          {/*    className="button-event"*/}
          {/*  >*/}
          {/*    변경*/}
          {/*  </div>*/}
          {/*</Panel>*/}
        </ReactFlow>
      )}

      {/* 드래그 개체 */}
      {/*<div className="absolute right-5 top-5 z-20">*/}
      {/*  <div*/}
      {/*    onDragStart={(event: any) => onDragStart(event, 'input')}*/}
      {/*    draggable*/}
      {/*  >*/}
      {/*    SampleNode.1*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* 드래그 노드 */}
      <motion.div
        id="side-drag-node"
        initial="close"
        animate={toggleSideMenu ? 'open' : 'close'}
        variants={{
          open: {
            display: 'block',
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
          },
          close: {
            opacity: 0,
            x: -300,
            y: 0,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
            // transitionEnd: { display: 'none' },
          },
        }}
        className="absolute left-2 top-2 w-52 flex justify-center items-center z-20"
      >
        <div
          style={{
            height: 'calc(100% - 1rem)',
          }}
          className="pb-3 border border-gray-300 bg-white rounded-md"
        >
          <div className="p-1 w-full h-full">
            {/* 닫기 버튼 */}
            <div
              onClick={() => setToggleSideMenu(false)}
              className="button-event flex justify-end items-center"
            >
              {/* 아이콘 */}
              <div className="w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['far', 'xmark']}
                  className="w-6 h-6 text-gray-600"
                />
              </div>
            </div>

            {/* Button Node */}
            <div
              onDragStart={(event: any) => onDragStart(event, 'buttonNode')}
              draggable
              className="flex justify-start items-center cursor-move"
            >
              {/* 아이콘 */}
              <div className="w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'play']}
                  className="w-4 h-4 text-indigo-500"
                />
              </div>

              {/* 제목 */}
              <span className="text-xs text-gray-600 font-semibold">
                Button
              </span>
            </div>

            {/* Console Node */}
            <div
              onDragStart={(event: any) => onDragStart(event, 'consoleNode')}
              draggable
              className="flex justify-start items-center cursor-move"
            >
              {/* 아이콘 */}
              <div className="w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'bars-sort']}
                  className="w-4 h-4 text-indigo-500"
                />
              </div>

              {/* 제목 */}
              <span className="text-xs text-gray-600 font-semibold">
                Console
              </span>
            </div>

            {/* Text Node */}
            <div
              onDragStart={(event: any) => onDragStart(event, 'textNode')}
              draggable
              className="flex justify-start items-center cursor-move"
            >
              {/* 아이콘 */}
              <div className="w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'text-size']}
                  className="w-4 h-4 text-indigo-500"
                />
              </div>

              {/* 제목 */}
              <span className="text-xs text-gray-600 font-semibold">Text</span>
            </div>

            {/* Random Number Node */}
            <div
              onDragStart={(event: any) =>
                onDragStart(event, 'randomNumberNode')
              }
              draggable
              className="flex justify-start items-center cursor-move"
            >
              {/* 아이콘 */}
              <div className="w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'shuffle']}
                  className="w-4 h-4 text-indigo-500"
                />
              </div>

              {/* 제목 */}
              <span className="text-xs text-gray-600 font-semibold">
                Random Number
              </span>
            </div>

            {/* Slider Number Node */}
            <div
              onDragStart={(event: any) =>
                onDragStart(event, 'sliderNumberNode')
              }
              draggable
              className="flex justify-start items-center cursor-move"
            >
              {/* 아이콘 */}
              <div className="w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'slider']}
                  className="w-4 h-4 text-indigo-500"
                />
              </div>

              {/* 제목 */}
              <span className="text-xs text-gray-600 font-semibold">
                Slider Number
              </span>
            </div>

            {/* Send Window Message Node */}
            <div
              onDragStart={(event: any) =>
                onDragStart(event, 'sendWindowMessageNode')
              }
              draggable
              className="flex justify-start items-center cursor-move"
            >
              {/* 아이콘 */}
              <div className="w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'paper-plane']}
                  className="w-4 h-4 text-indigo-500"
                />
              </div>

              {/* 제목 */}
              <span className="text-xs text-gray-600 font-semibold">
                Send Window Message
              </span>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        id="side-drag-node-2"
        initial="close"
        animate={toggleSideMenu ? 'close' : 'open'}
        variants={{
          open: {
            display: 'block',
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
          },
          close: {
            opacity: 0,
            x: -300,
            y: 0,
            transition: {
              type: 'spring',
              // bounce: 0,
              duration: 0.3,
            },
            // transitionEnd: { display: 'none' },
          },
        }}
        className="absolute left-2 top-2 flex justify-center items-center z-20"
      >
        {/* 아이콘 */}
        <div
          onClick={() => setToggleSideMenu(true)}
          className="button-event w-10 h-10 flex justify-center items-center"
        >
          <FontAwesomeIcon
            icon={['far', 'bars']}
            className="w-5 h-5 text-gray-600"
          />
        </div>
      </motion.div>
    </div>
  );
};

//
interface INodeProps {
  value: any;
}
const TextUpdaterNode = ({ data }: NodeProps<INodeProps>) => {
  const onChange = useCallback((evt: any) => {
    console.log(evt.target.value);
  }, []);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{
          top: 20,
        }}
        // isConnectable={false}
      >
        <div className="absolute left-2 top-1/2 -translate-y-1/2 flex justify-start items-center bg-rose-300 leading-none">
          <span className="text-xs truncate">Handle.1</span>
        </div>
      </Handle>
      <Handle
        id="b"
        type="target"
        position={Position.Left}
        // className="relative w-4 h-4 !bg-blue-900 z-20"
      >
        <div className="absolute left-2 top-1/2 -translate-y-1/2 flex justify-start items-center bg-blue-300 leading-none">
          <span className="text-xs truncate">Handle.2</span>
        </div>
      </Handle>

      <div className="px-14 py-3 bg-white/70 border border-gray-500 rounded z-10">
        <label htmlFor="text">Text:</label>
        <Input
          placeholder="Input"
          value={data.value}
          className="nodrag nowheel"
        />
      </div>

      <Handle type="source" position={Position.Right} className="relative z-20">
        <div className="absolute right-2 top-1/2 -translate-y-1/2 w-10 flex justify-end items-center bg-amber-300 leading-none">
          <span className="text-xs truncate">Handle</span>
        </div>
      </Handle>
    </>
  );
};

const SampleNode = ({ data }: NodeProps<INodeProps>) => {
  const nodeId = useNodeId();

  const onChange = useCallback((evt: any) => {
    console.log(evt.target.value);
  }, []);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        style={{
          top: 20,
        }}
        // isConnectable={false}
        className="!w-2 !h-2 !bg-blue-600 !rounded-none"
      >
        <div className="absolute left-2 top-1/2 -translate-y-1/2 flex justify-start items-center bg-rose-300 leading-none">
          <span className="text-xs truncate">{nodeId}</span>
        </div>
      </Handle>
      <Handle
        id="b"
        type="target"
        position={Position.Left}
        // className="relative w-4 h-4 !bg-blue-900 z-20"
      >
        <div className="absolute left-2 top-1/2 -translate-y-1/2 flex justify-start items-center bg-blue-300 leading-none">
          <span className="text-xs truncate">Handle.b</span>
        </div>
      </Handle>

      <div className="w-36 h-36 bg-white border border-gray-500 rounded z-10"></div>

      <Handle type="source" position={Position.Right} className="relative z-20">
        <div className="absolute right-2 top-1/2 -translate-y-1/2 w-10 flex justify-end items-center bg-amber-300 leading-none">
          <span className="text-xs truncate">Handle</span>
        </div>
      </Handle>
    </>
  );
};

export default function () {
  return (
    <ReactFlowProvider>
      <ProcessFlow />
    </ReactFlowProvider>
  );
}

// export default ProcessFlow;
