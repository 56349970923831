import { Memo } from '../layers/Memo';
import { ExcelSheet } from '../layers/ExcelSheet';
import { Config } from '../layers/Config';
import { ProcessFlow } from '../layers/ProcessFlow';
import { MapBasedStatus } from '../layers/MapBasedStatus';
import { ThreeModel } from '../layers/ThreeModel';
import { PdfViewer } from '../layers/PdfViewer';
import { PdfDragItem } from '../layers/PdfDragItem';
import { SampleChart } from '../layers/SampleChart';
import { Message } from '../layers/Message';
import { VideoMeeting } from '../layers/VideoMeeting';
import { Wasm } from '../layers/Wasm';
import { Validator } from '../layers/Validator';
import { ExtraApp } from '../layers/ExtraApp';

// 컴포넌트 목록을 정의함
export const components: any = {
  memo: Memo,
  excelSheet: ExcelSheet,
  processFlow: ProcessFlow,
  mapBasedStatus: MapBasedStatus,
  config: Config,
  threeModel: ThreeModel,
  pdfViewer: PdfViewer,
  pdfDragItem: PdfDragItem,
  sampleChart: SampleChart,
  message: Message,
  videoMeeting: VideoMeeting,
  wasm: Wasm,
  validator: Validator,
  extraApp: ExtraApp,
};
