import React, {
  Fragment,
  memo,
  PropsWithChildren,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useElementSize } from '@mantine/hooks';
import {
  Clone,
  OrbitControls,
  PerspectiveCamera,
  Sky,
  useGLTF,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';

interface ILayerProps {}

/**
 * Three Model
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ThreeModel = ({}: PropsWithChildren<ILayerProps>) => {
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 카메라 각도를 정의함
  const [sideX, setSideX] = useState<number>(10);
  const [sideY, setSideY] = useState<number>(10);
  const [sideZ, setSideZ] = useState<number>(10);

  // 3D 모델을 파일에서 불러옴
  const ThreeModel = () => {
    const { scene } = useGLTF('/models/mini_cooper_s/scene.gltf');

    return <Clone object={scene} />;
    //
    //
    // const fbx = useGLTF('/models/empty_shop/scene.gltf');
    //
    // return (
    //   <primitive
    //     object={fbx}
    //     dispose={null}
    //     position={[0, 0, 0]}
    //     scale={[0.01, 0.01, 0.01]}
    //   />
    // );
  };

  // 윈도우 메시지를 받음
  const eventListener = (event: MessageEvent<any>) => {
    // // 받은 윈도우 메시지를 정의함
    // let tmpReceiveData: any = {
    //   type: '',
    //   id: '',
    //   data: '',
    // };

    // console.log('> message event:', event);

    if (event.data?.type === 'processFlow') {
      let tmpData: any = event.data;

      switch (tmpData.id) {
        case '3D.X':
          setSideX(tmpData.data);
          break;

        case '3D.Y':
          setSideY(tmpData.data);
          break;

        case '3D.Z':
          setSideZ(tmpData.data);
          break;

        default:
          break;
      }
    }

    // if (event.data?.type !== undefined) {
    //   tmpInputData = event.data;
    // }
    //
    // // 현재 탭의 윈도우 메시지만 처리함
    // if (
    //   tmpInputData.type !== EWindowMessageType.DEFAULT ||
    //   !tmpInputData.command
    // ) {
    //   return;
    // }
    //
  };

  useEffect(() => {
    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, []);

  return (
    <div ref={contentRef} className="relative flex w-full h-full bg-slate-400">
      <Suspense fallback={null}>
        <Canvas id="three" className="w-full h-full">
          {/*<Sky*/}
          {/*  distance={3000}*/}
          {/*  turbidity={8}*/}
          {/*  rayleigh={10}*/}
          {/*  mieCoefficient={0.005}*/}
          {/*  mieDirectionalG={0.8}*/}
          {/*  sunPosition={[100, 100, 100]}*/}
          {/*  azimuth={0.25}*/}
          {/*/>*/}
          <ambientLight intensity={3} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={5} />
          <pointLight position={[10, 10, 10]} />
          <PerspectiveCamera makeDefault position={[sideX, sideY, sideZ]} />
          <OrbitControls />
          <ThreeModel />
        </Canvas>
      </Suspense>
    </div>
  );
};

export default ThreeModel;
