import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMessage } from '../../interfaces/app.interface';
import axios from 'axios';

const { TextArea } = Input;

interface ILayerProps {}

/**
 * 메시지
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Message = ({}: PropsWithChildren<ILayerProps>) => {
  const [content, setContent] = useState<string>('');

  // 내 소켓 아이디를 정의함
  const [mySocketId, setMySocketId] = useState<string>('');

  // 메시지 내역을 정의함
  const [messageLog, setMessageLog] = useState<IMessage[]>([]);

  const messageRef = useRef<HTMLDivElement>(null);

  const handleSendButton_onClick = useCallback(
    (event: any) => {
      event.preventDefault();

      if (content.trim() === '') {
        return;
      }

      // axios({
      //   method: 'GET',
      //   url: ['http://localhost:2001/manage/teams'].join(''),
      //   params: {
      //     content: content,
      //   },
      // })
      //   .then((response: any) => {
      //     // console.log('> response:', response);
      //     // setMessageLog((pre: IMessage[]) => [
      //     //   ...pre,
      //     //   {
      //     //     userId: '',
      //     //     socketId: mySocketId,
      //     //     content: content,
      //     //     createAt: 123456789,
      //     //   },
      //     // ]);
      //   })
      //   .catch((error: any) => {
      //     console.log('> error response:', error);
      //   });

      setMessageLog((pre: IMessage[]) => [
        ...pre,
        {
          userId: '',
          socketId: mySocketId,
          content: content,
          createAt: 123456789,
        },
      ]);

      setContent('');
    },
    [content],
  );

  // const handleSendButton_onClick = useCallback(() => {
  //   console.log('> content: ', content);
  // }, []);

  const handleItem_onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleItem_onDrop = useCallback((event: any) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/dragMapItem');

    let tmpType: any = JSON.parse(type);

    if (typeof type === 'undefined' || !type) {
      return;
    }

    setContent(`위도: ${tmpType.lat} / 경도: ${tmpType.lng}`);
  }, []);

  useEffect(() => {
    // 임시!!
    setMySocketId('1');

    // 임시!!
    setMessageLog([
      {
        userId: '',
        socketId: '1',
        content: '테스트입니다.',
        createAt: 123456789,
      },
      {
        userId: '',
        socketId: 'User #1',
        content: '하이',
        createAt: 123456789,
      },
      {
        userId: '',
        socketId: 'User #2',
        content: '메신저 테스트입니다.',
        createAt: 123456789,
      },
    ]);

    return () => {};
  }, []);

  useEffect(() => {
    messageRef.current?.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });

    return () => {};
  }, [messageLog]);

  return (
    <div className="bg-white w-full h-full">
      <div className="w-full h-full flex flex-col">
        {/* 메시지 내역 */}
        <div
          ref={messageRef}
          className="relative flex-grow w-full px-3 pb-1 space-y-2 overflow-y-auto"
        >
          {messageLog.map((item: IMessage, index: number) => {
            // 내 메시지를 출력함
            if (item.socketId === mySocketId) {
              return (
                <div className="flex justify-end items-center">
                  <div>
                    <div className="pl-2 flex justify-start items-center">
                      <span className="text-xs font-semibold text-gray-600">
                        나
                      </span>
                    </div>

                    <div className="px-3 py-1 bg-blue-500 rounded-t-full rounded-bl-full">
                      <span className="text-xs text-white">{item.content}</span>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="flex justify-start items-center">
                  <div>
                    <div className="pl-2 flex justify-start items-center">
                      <span className="text-xs font-semibold text-gray-600">
                        {item.socketId}
                      </span>
                    </div>

                    <div className="px-3 py-1 bg-gray-200 rounded-t-full rounded-br-full">
                      <span className="text-xs text-gray-800">
                        {item.content}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>

        {/* 메시지 입력 */}
        <div className="flex w-full h-16 border border-gray-400 rounded-md">
          <div className="grow h-full">
            <TextArea
              onDragOver={handleItem_onDragOver}
              onDrop={handleItem_onDrop}
              onChange={(event: any) => setContent(event.target.value)}
              onPressEnter={handleSendButton_onClick}
              // size="small"
              value={content}
              bordered={false}
              style={{ resize: 'none' }}
              className="w-full !h-full"
            />
          </div>

          <div className="flex-none w-12 h-full pr-1.5 flex justify-center items-center">
            {/* 아이콘 */}
            <div
              onClick={handleSendButton_onClick}
              className="button-event w-full h-12 flex justify-center items-center bg-blue-500 rounded-md"
            >
              <FontAwesomeIcon
                icon={['fas', 'envelope']}
                className="w-4 h-4 text-white"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
