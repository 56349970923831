import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

interface ILayerProps {}

/**
 * 메모
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Memo = ({}: PropsWithChildren<ILayerProps>) => {
  const [content, setContent] = useState<string>('');

  const handleItem_onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleItem_onDrop = useCallback((event: any) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/dragMapItem');

    let tmpType: any = JSON.parse(type);

    if (typeof type === 'undefined' || !type) {
      return;
    }

    setContent(`위도: ${tmpType.lat} / 경도: ${tmpType.lng}`);
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="bg-white w-full h-full">
      <TextArea
        onDragOver={handleItem_onDragOver}
        onDrop={handleItem_onDrop}
        onChange={(event: any) => setContent(event.target.value)}
        size="large"
        value={content}
        style={{ resize: 'none' }}
        className="w-full !h-full"
      />
    </div>
  );
};

export default Memo;
