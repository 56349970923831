import { atom } from 'recoil';
import { ILayer, ILayerSplitWay } from '../interfaces/app.interface';

/**
 * 레이어들
 */
export const layerStore = atom({
  key: 'layerStore',
  default: [] as ILayer[],
});

/**
 * 추가할 레이어
 */
export const addLayerStore = atom({
  key: 'addLayerStore',
  default: null as ILayer | null,
});

/**
 * 수정할 레이어
 */
export const modifyLayerStore = atom({
  key: 'modifyLayerStore',
  default: null as ILayer | null,
});

/**
 * 삭제할 레이어
 */
export const removeLayerStore = atom({
  key: 'removeLayerStore',
  default: null as string | null,
});

/**
 * 전체 레이어 삭제
 */
export const removeAllLayerStore = atom({
  key: 'removeAllLayerStore',
  default: false as boolean,
});

/**
 * 선택한 레이어
 */
export const selectedLayerStore = atom({
  key: 'selectedLayerStore',
  default: null as string | null,
});

/**
 * 드래그 중인 레이어
 */
export const draggingLayerStore = atom({
  key: 'draggingLayerStore',
  default: null as string | null,
});

/**
 * 최소화한 레이어
 */
export const minimizedLayerStore = atom({
  key: 'minimizedLayerStore',
  default: null as string | null,
});

/**
 * 최대화한 레이어
 */
export const maximizedLayerStore = atom({
  key: 'maximizedLayerStore',
  default: null as string | null,
});

/**
 * 원래 크기 및 위치로 복원한 레이어
 */
export const restoreLayerStore = atom({
  key: 'restoreLayerStore',
  default: null as string | null,
});

/**
 * 선택한 레이어의 레이어 아이템 페이지로 이동
 */
export const moveToSelectLayerItemPageStore = atom({
  key: 'moveToSelectLayerItemPageStore',
  default: false as boolean,
});

/**
 * 생성한 레이어 목록 팝업 출력
 */
export const layerListPopupStore = atom({
  key: 'layerListPopupStore',
  default: false as boolean,
});

/**
 * 드래그 중인 레이어를 화면 양쪽 끝으로 이동
 */
export const splitDraggingLayerStore = atom({
  key: 'splitDraggingLayerStore',
  default: null as 'left' | 'right' | null,
});
