import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

interface ILayoutProps {
  wallpaperType?: 'color' | 'image';
  wallpaperSource?: string;
}

/**
 * 배경화면
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Wallpaper = ({
  wallpaperType = 'color',
  wallpaperSource = 'bg-gray-100',
}: PropsWithChildren<ILayoutProps>) => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="absolute left-0 top-0 w-screen h-screen z-10">
      {/* 색상 */}
      {wallpaperType === 'color' && (
        <div className={['w-full h-full', wallpaperSource].join(' ')}></div>
      )}

      {/* 이미지 */}
      {wallpaperType === 'image' && (
        <div
          style={{
            backgroundImage: `url(${wallpaperSource})`,
          }}
          className="w-full h-full bg-no-repeat bg-cover bg-center"
        ></div>
      )}
    </div>
  );
};

export default Wallpaper;
