import React, {
  createElement,
  Fragment,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useElementSize } from '@mantine/hooks';
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ILayer } from '../../interfaces/app.interface';
import { v4 as uuidv4 } from 'uuid';
import * as componentUtil from '../../utils/component.util';
import { useRecoilState } from 'recoil';
import { addLayerStore } from '../../stores/layer.store';

interface ILayerProps {}

/**
 * Map-Based Status
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MapBasedStatus = ({}: PropsWithChildren<ILayerProps>) => {
  // 추가할 레이어 저장소를 정의함
  const [addLayer, setAddLayer] = useRecoilState<ILayer | null>(addLayerStore);

  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // API 키를 정의함
  // const googleMapsPlatformApiKey: string =
  //   'AIzaSyDFLgUV29Hv1UO8gDHz26OqEfSPyEPee_k';
  const googleMapsPlatformApiKey: string =
    'AIzaSyA53Q_SkM8VOunYRveMogrwXFAfXuoj9Ro';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsPlatformApiKey,
  });

  const center = {
    lat: 37.541,
    lng: 126.986,
  };

  const [map, setMap] = useState<google.maps.Map | null>(null);

  // 마커를 정의함
  const [marker, setMarker] = useState<any>([]);

  const onLoad = useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const handleChartButton_onClick = () => {
    let tmpLayer: ILayer | null = null;

    tmpLayer = {
      id: uuidv4(),
      icon: ['fad', 'chart-simple'],
      title: 'Chart Detail Information',
      size: {
        width: 700,
        height: 400,
      },
      minimized: false,
      maximized: false,
      showMinimizeButton: false,
      showMaximizeButton: false,
      showCloseButton: true,
      resizing: true,
      lockPosition: false,
      doubleClickToMaximize: false,
      children: createElement(componentUtil.components['sampleChart'], {}),
    };

    // 레이어를 추가함
    setAddLayer(tmpLayer);
  };

  const handleItem_onDragStart = (event: any, data: any) => {
    event.dataTransfer.setData('application/dragMapItem', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  useEffect(() => {
    //
    setMarker([
      {
        position: {
          lat: 37.541,
          lng: 126.986,
        },
      },
      {
        position: {
          lat: 37.167967,
          lng: 129.015712,
        },
      },
      {
        position: {
          lat: 37.650512,
          lng: 127.265628,
        },
      },
      {
        position: {
          lat: 37.072142,
          lng: 127.302538,
        },
      },
    ]);
  }, []);

  return (
    <div
      ref={contentRef}
      className="google-map relative bg-white flex w-full h-full"
    >
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          // options={{
          //   disableDefaultUI: true,
          //   styles: [
          //     {
          //       featureType: 'poi',
          //       elementType: 'labels',
          //       stylers: [{ visibility: 'off' }],
          //     },
          //   ],
          // }}
        >
          {/* Child components, such as markers, info windows, etc. */}
          {marker.map((item: any, index: number) => (
            <Fragment key={index}>
              <MarkerF position={item.position} />

              <InfoWindowF
                position={item.position}
                options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
              >
                <div className="relative w-60 h-32 px-4 py-4 flex justify-center items-center backdrop-blur-sm bg-white/80 border border-gray-300 rounded shadow-md space-x-2">
                  <div className="grow h-full">
                    {/*<MyResponsivePie data={pieData} />*/}
                    <MyResponsiveBar data={barData} />

                    {/*<div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">*/}
                    {/*  <span className="text-md font-semibold text-slate-600">*/}
                    {/*    96*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                  </div>

                  <div className="space-y-1">
                    <div
                      onClick={handleChartButton_onClick}
                      className="button-event w-16 h-7 flex justify-center items-center bg-indigo-500 rounded"
                    >
                      <span className="text-xs font-semibold text-white">
                        Chart
                      </span>
                    </div>

                    <div
                      onDragStart={(event: any) =>
                        handleItem_onDragStart(event, item.position)
                      }
                      draggable
                      className="w-16 h-7 flex justify-center items-center bg-slate-600 rounded cursor-move"
                    >
                      <span className="text-xs font-semibold text-white">
                        Drag
                      </span>
                    </div>
                  </div>
                </div>
              </InfoWindowF>
            </Fragment>
          ))}
        </GoogleMap>
      )}

      {/* 제어 버튼 */}
      <div
        onClick={() => {
          map?.setZoom(10);
        }}
        className="absolute left-3 top-20 space-y-2 z-20"
      >
        {/* 기본 장소로 이동 */}
        <div
          onClick={() => {
            map?.setZoom(10);

            const bounds = new window.google.maps.LatLngBounds(center);
            map?.fitBounds(bounds);
          }}
          className="button-event px-2 py-1 bg-black rounded drop-shadow-md"
        >
          <span className="text-sm font-semibold text-white">Reset</span>
        </div>

        {/* 차트 */}
        {/*<div*/}
        {/*  onClick={() => {*/}
        {/*    //*/}
        {/*    setMarker([*/}
        {/*      {*/}
        {/*        position: {*/}
        {/*          lat: 37.341,*/}
        {/*          lng: 126.386,*/}
        {/*        },*/}
        {/*      },*/}
        {/*      {*/}
        {/*        position: {*/}
        {/*          lat: 37.471,*/}
        {/*          lng: 126.966,*/}
        {/*        },*/}
        {/*      },*/}
        {/*    ]);*/}
        {/*  }}*/}
        {/*  className="button-event px-2 py-1 bg-black rounded drop-shadow-md"*/}
        {/*>*/}
        {/*  <span className="text-sm font-semibold text-white">Chart</span>*/}
        {/*</div>*/}

        {/* 테스트 */}
        {/*<div*/}
        {/*  onClick={() => {*/}
        {/*    // setSample('테스트');*/}
        {/*  }}*/}
        {/*  className="button-event px-2 py-1 bg-black rounded drop-shadow-md"*/}
        {/*>*/}
        {/*  <span className="text-sm font-semibold text-white">Test</span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

// https://www.npmjs.com/package/@react-google-maps/api

const MyResponsivePie = ({ data /* see pieData tab */ }: any) => (
  <ResponsivePie
    data={data}
    margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 0.2]],
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: 'color',
      modifiers: [['darker', 2]],
    }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: 'ruby',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'c',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'go',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'python',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'scala',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'lisp',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'elixir',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'javascript',
        },
        id: 'lines',
      },
    ]}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
);

const pieData: any = [
  {
    id: 'python',
    label: 'python',
    value: 547,
    color: 'hsl(109, 70%, 50%)',
  },
  {
    id: 'make',
    label: 'make',
    value: 408,
    color: 'hsl(189, 70%, 50%)',
  },
  {
    id: 'css',
    label: 'css',
    value: 333,
    color: 'hsl(142, 70%, 50%)',
  },
  {
    id: 'elixir',
    label: 'elixir',
    value: 436,
    color: 'hsl(320, 70%, 50%)',
  },
  {
    id: 'sass',
    label: 'sass',
    value: 432,
    color: 'hsl(333, 70%, 50%)',
  },
];

///////////////////////////////////////////////

const MyResponsiveBar = ({ data }: any) => (
  <ResponsiveBar
    data={data}
    keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
    indexBy="country"
    margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={{ scheme: 'nivo' }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#38bcb2',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#eed312',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: 'fries',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'sandwich',
        },
        id: 'lines',
      },
    ]}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'country',
      legendPosition: 'middle',
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'food',
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={(e) =>
      e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
    }
  />
);

const barData: any = [
  {
    country: 'AD',
    'hot dog': 71,
    'hot dogColor': 'hsl(237, 70%, 50%)',
    burger: 149,
    burgerColor: 'hsl(299, 70%, 50%)',
    sandwich: 120,
    sandwichColor: 'hsl(53, 70%, 50%)',
    kebab: 166,
    kebabColor: 'hsl(197, 70%, 50%)',
    fries: 140,
    friesColor: 'hsl(198, 70%, 50%)',
    donut: 114,
    donutColor: 'hsl(93, 70%, 50%)',
  },
  {
    country: 'AE',
    'hot dog': 7,
    'hot dogColor': 'hsl(133, 70%, 50%)',
    burger: 4,
    burgerColor: 'hsl(95, 70%, 50%)',
    sandwich: 149,
    sandwichColor: 'hsl(16, 70%, 50%)',
    kebab: 189,
    kebabColor: 'hsl(25, 70%, 50%)',
    fries: 54,
    friesColor: 'hsl(278, 70%, 50%)',
    donut: 104,
    donutColor: 'hsl(140, 70%, 50%)',
  },
  {
    country: 'AF',
    'hot dog': 156,
    'hot dogColor': 'hsl(22, 70%, 50%)',
    burger: 130,
    burgerColor: 'hsl(211, 70%, 50%)',
    sandwich: 100,
    sandwichColor: 'hsl(154, 70%, 50%)',
    kebab: 130,
    kebabColor: 'hsl(186, 70%, 50%)',
    fries: 25,
    friesColor: 'hsl(49, 70%, 50%)',
    donut: 185,
    donutColor: 'hsl(353, 70%, 50%)',
  },
  {
    country: 'AG',
    'hot dog': 130,
    'hot dogColor': 'hsl(125, 70%, 50%)',
    burger: 96,
    burgerColor: 'hsl(274, 70%, 50%)',
    sandwich: 10,
    sandwichColor: 'hsl(51, 70%, 50%)',
    kebab: 91,
    kebabColor: 'hsl(269, 70%, 50%)',
    fries: 92,
    friesColor: 'hsl(119, 70%, 50%)',
    donut: 81,
    donutColor: 'hsl(203, 70%, 50%)',
  },
  {
    country: 'AI',
    'hot dog': 112,
    'hot dogColor': 'hsl(336, 70%, 50%)',
    burger: 50,
    burgerColor: 'hsl(203, 70%, 50%)',
    sandwich: 30,
    sandwichColor: 'hsl(0, 70%, 50%)',
    kebab: 73,
    kebabColor: 'hsl(335, 70%, 50%)',
    fries: 172,
    friesColor: 'hsl(51, 70%, 50%)',
    donut: 154,
    donutColor: 'hsl(86, 70%, 50%)',
  },
  {
    country: 'AL',
    'hot dog': 61,
    'hot dogColor': 'hsl(79, 70%, 50%)',
    burger: 46,
    burgerColor: 'hsl(30, 70%, 50%)',
    sandwich: 188,
    sandwichColor: 'hsl(177, 70%, 50%)',
    kebab: 146,
    kebabColor: 'hsl(18, 70%, 50%)',
    fries: 77,
    friesColor: 'hsl(42, 70%, 50%)',
    donut: 195,
    donutColor: 'hsl(162, 70%, 50%)',
  },
  {
    country: 'AM',
    'hot dog': 18,
    'hot dogColor': 'hsl(349, 70%, 50%)',
    burger: 95,
    burgerColor: 'hsl(288, 70%, 50%)',
    sandwich: 59,
    sandwichColor: 'hsl(333, 70%, 50%)',
    kebab: 153,
    kebabColor: 'hsl(26, 70%, 50%)',
    fries: 3,
    friesColor: 'hsl(200, 70%, 50%)',
    donut: 4,
    donutColor: 'hsl(153, 70%, 50%)',
  },
];

export default MapBasedStatus;
