import React, {
  createElement,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { useClickOutside, useResizeObserver } from '@mantine/hooks';
import { ILayer, IMenuItem } from '../../interfaces/app.interface';
import {
  addLayerStore,
  layerListPopupStore,
  layerStore,
  modifyLayerStore,
  moveToSelectLayerItemPageStore,
  removeLayerStore,
  selectedLayerStore,
} from '../../stores/layer.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { ScrollArea } from '@mantine/core';
import { motion } from 'framer-motion';
import { menuStore } from '../../stores/menu.store';
import { v4 as uuidv4 } from 'uuid';
import * as componentUtil from '../../utils/component.util';

interface ILayoutProps {}

/**
 * 메뉴
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Menu = ({}: PropsWithChildren<ILayoutProps>) => {
  // 개체 밖의 클릭 이벤트를 정의함
  const ref = useClickOutside(() => setMenu(false));

  // 추가할 레이어 저장소를 정의함
  const [addLayer, setAddLayer] = useRecoilState<ILayer | null>(addLayerStore);

  // 메뉴 출력을 정의함
  const [menu, setMenu] = useRecoilState<boolean>(menuStore);

  // 메뉴의 높이를 정의함
  const [menuHeight, setMenuHeight] = useState<number>(0);

  // 메뉴를 정의함
  const menuItem: IMenuItem[] = [
    {
      type: 'item',
      id: 'excel-sheet-free',
      title: 'Excel Sheet - Free',
      icon: ['fal', 'diagram-cells'],
      component: 'excel-sheet-free',
      enable: true,
    },
    {
      type: 'item',
      id: 'excel-sheet-price',
      title: 'Excel Sheet - Price',
      icon: ['fal', 'diagram-cells'],
      component: 'excel-sheet-price',
      enable: true,
    },
    // {
    //   type: 'item',
    //   id: 'excel-sheet-google',
    //   title: 'Excel Sheet - Google Sheet',
    //   icon: ['fal', 'diagram-cells'],
    //   component: 'excel-sheet-google',
    //   enable: true,
    // },
    {
      type: 'item',
      id: 'pdf-viewer',
      title: 'PDF Viewer',
      icon: ['fal', 'scroll'],
      component: 'pdf-viewer',
      enable: true,
    },
    {
      type: 'item',
      id: 'image-gallery',
      title: 'Image Gallery',
      icon: ['fal', 'image'],
      enable: true,
    },
    {
      type: 'item',
      id: 'drag-and-drop',
      title: 'Drag & Drop Data',
      icon: ['fal', 'diagram-next'],
      enable: true,
    },
    {
      type: 'item',
      id: 'chart',
      title: 'Chart',
      icon: ['fal', 'chart-mixed-up-circle-dollar'],
      enable: true,
    },
    {
      type: 'item',
      id: 'map-based-status',
      title: 'Map-Based Status',
      icon: ['fal', 'map-location-dot'],
      component: 'map-based-status',
      enable: true,
    },
    {
      type: 'item',
      id: 'file-storage',
      title: 'File Storage',
      icon: ['fal', 'folder'],
      enable: true,
    },
    {
      type: 'item',
      id: 'process-flow',
      title: 'Process Flow',
      icon: ['fal', 'arrow-progress'],
      component: 'process-flow',
      enable: true,
    },
    {
      type: 'item',
      id: 'three-model',
      title: '3D Model',
      icon: ['fal', 'cube'],
      component: 'three-model',
      enable: true,
    },
    {
      type: 'item',
      id: 'message',
      title: 'Message',
      icon: ['fal', 'comment'],
      component: 'message',
      enable: true,
    },
    {
      type: 'item',
      id: 'notification-history',
      title: 'Notification History',
      icon: ['fal', 'rectangle-history'],
      enable: true,
    },
    {
      type: 'item',
      id: 'teams-interlock',
      title: 'Teams Interlock',
      icon: ['fal', 'right-left-large'],
      enable: true,
    },
    {
      type: 'item',
      id: 'video-meeting',
      title: 'Video Meeting',
      icon: ['fal', 'video'],
      component: 'video-meeting',
      enable: true,
    },
    {
      type: 'item',
      id: 'remote-control',
      title: 'Remote Control',
      icon: ['fal', 'wifi'],
      enable: true,
    },
    {
      type: 'item',
      id: 'wasm',
      title: 'WebAssembly',
      icon: ['fal', 'binary'],
      component: 'wasm',
      enable: true,
    },
    {
      type: 'item',
      id: 'validator',
      title: 'Validator',
      icon: ['fal', 'badge-check'],
      component: 'validator',
      enable: true,
    },
    {
      type: 'item',
      id: 'extraApp',
      title: 'Extra App',
      icon: ['fal', 'rocket-launch'],
      component: 'extraApp',
      enable: true,
    },
    {
      type: 'item',
      id: 'language',
      title: 'Language',
      icon: ['fal', 'globe'],
      enable: true,
    },
    {
      type: 'item',
      id: 'component-store',
      title: 'Component Store',
      icon: ['fal', 'arrow-down-to-square'],
      enable: true,
    },
    {
      type: 'item',
      id: 'config',
      title: 'Configuration',
      icon: ['fal', 'gear-complex'],
      component: 'config',
      enable: true,
    },
    {
      type: 'divider',
      id: uuidv4(),
      title: '',
      icon: null,
      enable: true,
    },
    {
      type: 'item',
      id: 'platform',
      title: 'Platform',
      icon: ['fal', 'circle-nodes'],
      enable: true,
    },
    {
      type: 'item',
      id: 'pricing',
      title: 'Pricing',
      icon: ['fal', 'circle-dollar'],
      enable: true,
    },
    {
      type: 'item',
      id: 'update',
      title: 'Update',
      icon: ['fal', 'rotate'],
      enable: true,
    },
  ];

  // 컴포넌트를 불러옴
  const loadComponent = (item: string) => {
    let tmpLayer: ILayer | null = null;

    switch (item) {
      // Excel Sheet(무료)
      case 'excel-sheet-free':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'diagram-cells'],
          title: 'Excel Sheet - Free',
          size: {
            width: 900,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['excelSheet'], {
            type: 'free',
          }),
        };
        break;

      // Excel Sheet(유료)
      case 'excel-sheet-price':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'diagram-cells'],
          title: 'Excel Sheet - Price',
          size: {
            width: 900,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['excelSheet'], {
            type: 'price',
          }),
        };
        break;

      // Excel Sheet(구글)
      case 'excel-sheet-google':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'diagram-cells'],
          title: 'Excel Sheet - Google Sheet',
          size: {
            width: 900,
            height: 600,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['excelSheet'], {
            type: 'google',
          }),
        };
        break;

      // Process Flow
      case 'process-flow':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'arrow-progress'],
          title: 'Process Flow',
          size: {
            width: 1200,
            height: 600,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['processFlow'], {}),
        };
        break;

      // Map-Based Status
      case 'map-based-status':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'map-location-dot'],
          title: 'Map-Based Status',
          size: {
            width: 900,
            height: 600,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(
            componentUtil.components['mapBasedStatus'],
            {},
          ),
        };
        break;

      // 3D Model
      case 'three-model':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'cube'],
          title: '3D Model',
          size: {
            width: 700,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['threeModel'], {}),
        };
        break;

      // PDF Viewer
      case 'pdf-viewer':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'scroll'],
          title: 'PDF Viewer',
          size: {
            width: 700,
            height: 600,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['pdfViewer'], {}),
        };
        break;

      // Message
      case 'message':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'comment'],
          title: 'Message',
          size: {
            width: 300,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['message'], {}),
        };
        break;

      // Video Meeting
      case 'video-meeting':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'video'],
          title: 'Video Meeting',
          size: {
            width: 600,
            height: 550,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['videoMeeting'], {}),
        };
        break;

      // WebAssembly
      case 'wasm':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'binary'],
          title: 'WebAssembly',
          size: {
            width: 900,
            height: 400,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['wasm'], {}),
        };
        break;

      // Validator
      case 'validator':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'badge-check'],
          title: 'Validator',
          size: {
            width: 200,
            height: 300,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['validator'], {}),
        };
        break;

      // Extra APP
      case 'extraApp':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'rocket-launch'],
          title: 'Extra APP',
          size: {
            width: 900,
            height: 600,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['extraApp'], {}),
        };
        break;

      // Configuration
      case 'config':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'gear-complex'],
          title: 'Configuration',
          size: {
            width: 700,
            height: 400,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['config'], {}),
        };
        break;

      default:
        break;
    }

    if (tmpLayer !== null) {
      // 레이어를 추가함
      setAddLayer(tmpLayer);
    }

    // 메뉴를 닫음
    setMenu(false);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (!menu) {
      return;
    }

    // 바탕화면 개체를 불러옴
    let desktop = document.querySelector('#desktop') as HTMLDivElement;

    // 메뉴 개체를 불러옴
    let menuArea = document.querySelector('#menu') as HTMLDivElement;

    if (menuArea.clientHeight + 100 > desktop.clientHeight) {
      setMenuHeight(desktop.clientHeight - 100);
    } else {
      setMenuHeight(desktop.clientHeight);
    }
  }, [menu]);

  return (
    <motion.div
      id="menu"
      ref={ref}
      initial="close"
      animate={menu ? 'open' : 'close'}
      variants={{
        open: {
          display: 'block',
          opacity: 1,
          x: 0,
          y: 0,
          transition: {
            type: 'spring',
            // bounce: 0,
            duration: 0.3,
          },
        },
        close: {
          opacity: 0,
          x: 0,
          y: 1000,
          transition: {
            type: 'spring',
            // bounce: 0,
            duration: 0.3,
          },
          // transitionEnd: { display: 'none' },
        },
      }}
      // style={{
      //   maxHeight: '500px',
      // }}
      className="absolute left-5 bottom-5 w-88 p-2 backdrop-blur bg-white/70 rounded shadow-md select-none pointer-events-auto z-90"
    >
      <ScrollArea
        style={{
          height: '500px',
        }}
      >
        <div className="space-y-0.5">
          {/* 메뉴 */}
          {menuItem.map((item: IMenuItem, index: number) => {
            switch (item.type) {
              // 메뉴
              case 'item':
                return (
                  <div
                    key={item.id}
                    onClick={() => loadComponent(item.component!)}
                    className="menu-button-event px-3 py-2 flex justify-start items-center space-x-3 hover:bg-blue-900/70 hover:rounded"
                  >
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={item.icon!}
                        className="w-5 h-5 text-cyan-700"
                      />
                    </div>
                    <div>
                      <span className="text-sm font-gray-600 text-bold">
                        {item.title}
                      </span>
                    </div>
                  </div>
                );

              // 구분선
              case 'divider':
                return (
                  <div key={item.id}>
                    <div className="w-full h-px border-t border-gray-400"></div>
                    <div className="w-full h-px border-t border-gray-300"></div>
                  </div>
                );

              default:
                break;
            }
          })}
        </div>
      </ScrollArea>
    </motion.div>
  );
};

export default Menu;
