import React, {
  createElement,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { useClickOutside, useResizeObserver } from '@mantine/hooks';
import { IConfig, ILayer } from '../../interfaces/app.interface';
import {
  addLayerStore,
  layerListPopupStore,
  layerStore,
  modifyLayerStore,
  moveToSelectLayerItemPageStore,
  removeLayerStore,
  selectedLayerStore,
} from '../../stores/layer.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { ScrollArea } from '@mantine/core';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import * as componentUtil from '../../utils/component.util';
import { configStore } from '../../stores/config.store';

interface ILayoutProps {
  wallpaperType?: 'color' | 'image';
  wallpaperSource?: string;
}

/**
 * 바탕화면용 단축 아이콘
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ShortCut = ({}: PropsWithChildren<ILayoutProps>) => {
  // 추가할 레이어 저장소를 정의함
  const [addLayer, setAddLayer] = useRecoilState<ILayer | null>(addLayerStore);

  // 환경설정 저장소를 정의함
  const [config, setConfig] = useRecoilState<IConfig>(configStore);

  // 컴포넌트를 불러옴
  const loadComponent = (item: string) => {
    let tmpLayer: ILayer | null = null;

    switch (item) {
      // Excel Sheet(무료)
      case 'excel-sheet-free':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'diagram-cells'],
          title: 'Excel Sheet - Free',
          size: {
            width: 900,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['excelSheet'], {
            type: 'free',
          }),
        };
        break;

      // Excel Sheet(유료)
      case 'excel-sheet-price':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'diagram-cells'],
          title: 'Excel Sheet - Price',
          size: {
            width: 900,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['excelSheet'], {
            type: 'price',
          }),
        };
        break;

      // Process Flow
      case 'process-flow':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'arrow-progress'],
          title: 'Process Flow',
          size: {
            width: 1200,
            height: 600,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['processFlow'], {}),
        };
        break;

      // Map-Based Status
      case 'map-based-status':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'map-location-dot'],
          title: 'Map-Based Status',
          size: {
            width: 900,
            height: 600,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(
            componentUtil.components['mapBasedStatus'],
            {},
          ),
        };
        break;

      // 3D Model
      case 'three-model':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'cube'],
          title: '3D Model',
          size: {
            width: 700,
            height: 500,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['threeModel'], {}),
        };
        break;

      // PDF Drag Item
      case 'pdf-drag-item':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'file-pdf'],
          title: 'PDF Drag Item',
          size: {
            width: 200,
            height: 300,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['pdfDragItem'], {}),
        };
        break;

      // WebAssembly
      case 'wasm':
        tmpLayer = {
          id: uuidv4(),
          icon: ['fad', 'binary'],
          title: 'WebAssembly',
          size: {
            width: 900,
            height: 400,
          },
          minimized: false,
          maximized: false,
          showMinimizeButton: false,
          showMaximizeButton: false,
          showCloseButton: true,
          resizing: true,
          lockPosition: false,
          doubleClickToMaximize: false,
          children: createElement(componentUtil.components['wasm'], {}),
        };
        break;

      default:
        break;
    }

    if (tmpLayer !== null) {
      // 레이어를 추가함
      setAddLayer(tmpLayer);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {config.enableShortCut && (
        <div
          style={{ height: 'calc(100vh - 120px)' }}
          className="object-to-left-appear absolute right-7 top-5 flex flex-col flex-wrap-reverse gap-x-5 gap-y-3 z-20"
        >
          {/* 메뉴 */}
          <div
            onClick={() => loadComponent('excel-sheet-price')}
            className="button-event w-20 h-20 flex justify-center items-center"
          >
            <div className="space-y-2">
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'diagram-cells']}
                  className="w-10 h-10 text-blue-300"
                />
              </div>

              {/* 제목 */}
              <div className="w-20 flex justify-center items-center">
                <span className="text-xs font-semibold text-gray-200 truncate">
                  Excel Sheet - Price
                </span>
              </div>
            </div>
          </div>

          {/* 메뉴 */}
          {/*<div*/}
          {/*  onClick={() => loadComponent('chart')}*/}
          {/*  className="button-event w-20 h-20 flex justify-center items-center"*/}
          {/*>*/}
          {/*  <div className="space-y-2">*/}
          {/*    /!* 아이콘 *!/*/}
          {/*    <div className="flex justify-center items-center">*/}
          {/*      <FontAwesomeIcon*/}
          {/*        icon={['fad', 'chart-mixed-up-circle-dollar']}*/}
          {/*        className="w-10 h-10 text-blue-300"*/}
          {/*      />*/}
          {/*    </div>*/}

          {/*    /!* 제목 *!/*/}
          {/*    <div className="w-20 flex justify-center items-center">*/}
          {/*      <span className="text-xs font-semibold text-gray-200 truncate">*/}
          {/*        Chart*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/* 메뉴 */}
          <div
            onClick={() => loadComponent('map-based-status')}
            className="button-event w-20 h-20 flex justify-center items-center"
          >
            <div className="space-y-2">
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'map-location-dot']}
                  className="w-10 h-10 text-blue-300"
                />
              </div>

              {/* 제목 */}
              <div className="w-20 flex justify-center items-center">
                <span className="text-xs font-semibold text-gray-200 truncate">
                  Map-Based Status
                </span>
              </div>
            </div>
          </div>

          {/* 메뉴 */}
          <div
            onClick={() => loadComponent('process-flow')}
            className="button-event w-20 h-20 flex justify-center items-center"
          >
            <div className="space-y-2">
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'arrow-progress']}
                  className="w-10 h-10 text-blue-300"
                />
              </div>

              {/* 제목 */}
              <div className="w-20 flex justify-center items-center">
                <span className="text-xs font-semibold text-gray-200 truncate">
                  Process Flow
                </span>
              </div>
            </div>
          </div>

          {/* 메뉴 */}
          <div
            onClick={() => loadComponent('three-model')}
            className="button-event w-20 h-20 flex justify-center items-center"
          >
            <div className="space-y-2">
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'cube']}
                  className="w-10 h-10 text-blue-300"
                />
              </div>

              {/* 제목 */}
              <div className="w-20 flex justify-center items-center">
                <span className="text-xs font-semibold text-gray-200 truncate">
                  3D Model
                </span>
              </div>
            </div>
          </div>

          {/* 메뉴 */}
          <div
            onClick={() => loadComponent('pdf-drag-item')}
            className="button-event w-20 h-20 flex justify-center items-center"
          >
            <div className="space-y-2">
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'file-pdf']}
                  className="w-10 h-10 text-blue-300"
                />
              </div>

              {/* 제목 */}
              <div className="w-20 flex justify-center items-center">
                <span className="text-xs font-semibold text-gray-200 truncate">
                  PDF Drag
                </span>
              </div>
            </div>
          </div>

          {/* 메뉴 */}
          <div
            onClick={() => loadComponent('wasm')}
            className="button-event w-20 h-20 flex justify-center items-center"
          >
            <div className="space-y-2">
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fad', 'binary']}
                  className="w-10 h-10 text-blue-300"
                />
              </div>

              {/* 제목 */}
              <div className="w-20 flex justify-center items-center">
                <span className="text-xs font-semibold text-gray-200 truncate">
                  WebAssembly
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShortCut;
