import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ResponsiveBar } from '@nivo/bar';

interface ILayerProps {}

/**
 * 차트 상세정보
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const SampleChart = ({}: PropsWithChildren<ILayerProps>) => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="bg-white w-full h-full">
      <MyResponsiveBar data={barData} />
    </div>
  );
};

const MyResponsiveBar = ({ data }: any) => (
  <ResponsiveBar
    data={data}
    keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
    indexBy="country"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={{ scheme: 'nivo' }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#38bcb2',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#eed312',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: 'fries',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'sandwich',
        },
        id: 'lines',
      },
    ]}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'country',
      legendPosition: 'middle',
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'food',
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={(e) =>
      e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
    }
  />
);

const barData: any = [
  {
    country: 'AD',
    'hot dog': 71,
    'hot dogColor': 'hsl(237, 70%, 50%)',
    burger: 149,
    burgerColor: 'hsl(299, 70%, 50%)',
    sandwich: 120,
    sandwichColor: 'hsl(53, 70%, 50%)',
    kebab: 166,
    kebabColor: 'hsl(197, 70%, 50%)',
    fries: 140,
    friesColor: 'hsl(198, 70%, 50%)',
    donut: 114,
    donutColor: 'hsl(93, 70%, 50%)',
  },
  {
    country: 'AE',
    'hot dog': 7,
    'hot dogColor': 'hsl(133, 70%, 50%)',
    burger: 4,
    burgerColor: 'hsl(95, 70%, 50%)',
    sandwich: 149,
    sandwichColor: 'hsl(16, 70%, 50%)',
    kebab: 189,
    kebabColor: 'hsl(25, 70%, 50%)',
    fries: 54,
    friesColor: 'hsl(278, 70%, 50%)',
    donut: 104,
    donutColor: 'hsl(140, 70%, 50%)',
  },
  {
    country: 'AF',
    'hot dog': 156,
    'hot dogColor': 'hsl(22, 70%, 50%)',
    burger: 130,
    burgerColor: 'hsl(211, 70%, 50%)',
    sandwich: 100,
    sandwichColor: 'hsl(154, 70%, 50%)',
    kebab: 130,
    kebabColor: 'hsl(186, 70%, 50%)',
    fries: 25,
    friesColor: 'hsl(49, 70%, 50%)',
    donut: 185,
    donutColor: 'hsl(353, 70%, 50%)',
  },
  {
    country: 'AG',
    'hot dog': 130,
    'hot dogColor': 'hsl(125, 70%, 50%)',
    burger: 96,
    burgerColor: 'hsl(274, 70%, 50%)',
    sandwich: 10,
    sandwichColor: 'hsl(51, 70%, 50%)',
    kebab: 91,
    kebabColor: 'hsl(269, 70%, 50%)',
    fries: 92,
    friesColor: 'hsl(119, 70%, 50%)',
    donut: 81,
    donutColor: 'hsl(203, 70%, 50%)',
  },
  {
    country: 'AI',
    'hot dog': 112,
    'hot dogColor': 'hsl(336, 70%, 50%)',
    burger: 50,
    burgerColor: 'hsl(203, 70%, 50%)',
    sandwich: 30,
    sandwichColor: 'hsl(0, 70%, 50%)',
    kebab: 73,
    kebabColor: 'hsl(335, 70%, 50%)',
    fries: 172,
    friesColor: 'hsl(51, 70%, 50%)',
    donut: 154,
    donutColor: 'hsl(86, 70%, 50%)',
  },
  {
    country: 'AL',
    'hot dog': 61,
    'hot dogColor': 'hsl(79, 70%, 50%)',
    burger: 46,
    burgerColor: 'hsl(30, 70%, 50%)',
    sandwich: 188,
    sandwichColor: 'hsl(177, 70%, 50%)',
    kebab: 146,
    kebabColor: 'hsl(18, 70%, 50%)',
    fries: 77,
    friesColor: 'hsl(42, 70%, 50%)',
    donut: 195,
    donutColor: 'hsl(162, 70%, 50%)',
  },
  {
    country: 'AM',
    'hot dog': 18,
    'hot dogColor': 'hsl(349, 70%, 50%)',
    burger: 95,
    burgerColor: 'hsl(288, 70%, 50%)',
    sandwich: 59,
    sandwichColor: 'hsl(333, 70%, 50%)',
    kebab: 153,
    kebabColor: 'hsl(26, 70%, 50%)',
    fries: 3,
    friesColor: 'hsl(200, 70%, 50%)',
    donut: 4,
    donutColor: 'hsl(153, 70%, 50%)',
  },
];

export default SampleChart;
