import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  ILayer,
  ILayerSplitWay,
  IPosition,
  ISize,
} from '../../interfaces/app.interface';
import { atom, useRecoilState } from 'recoil';
import {
  draggingLayerStore,
  maximizedLayerStore,
  minimizedLayerStore,
  modifyLayerStore,
  moveToSelectLayerItemPageStore,
  removeLayerStore,
  restoreLayerStore,
  selectedLayerStore,
  splitDraggingLayerStore,
} from '../../stores/layer.store';
import { DraggableEvent } from 'react-draggable';
import { DraggableData, Position, ResizableDelta, Rnd } from 'react-rnd';
import { ResizeDirection } from 're-resizable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { useElementSize } from '@mantine/hooks';
import { ScrollArea } from '@mantine/core';

/**
 * 레이어 기반
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const LayerBase = ({
  id = '',
  icon,
  title,
  size,
  minSize,
  maxSize,
  latestSize,
  position,
  latestPosition,
  minimized,
  maximized,
  splitWay,
  showMinimizeButton,
  showMaximizeButton,
  showCloseButton,
  resizing,
  lockPosition,
  doubleClickToMaximize,
  zIndex,
  children,
}: PropsWithChildren<ILayer>) => {
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 수정할 레이어를 정의함
  const [modifyLayer, setModifyLayer] = useRecoilState<ILayer | null>(
    modifyLayerStore,
  );

  // 삭제할 레이어를 정의함
  const [removeLayer, setRemoveLayer] = useRecoilState<string | null>(
    removeLayerStore,
  );

  // 선택한 레이어를 정의함
  const [selectedLayer, setSelectedLayer] = useRecoilState<string | null>(
    selectedLayerStore,
  );

  // 드래그 중인 레이어를 정의함
  const [draggingLayer, setDraggingLayer] = useRecoilState<string | null>(
    draggingLayerStore,
  );

  // 드래그 중인 레이어를 화면 양쪽 끝으로 이동 저장소를 정의함
  const [splitDraggingLayer, setSplitDraggingLayer] = useRecoilState<
    'left' | 'right' | null
  >(splitDraggingLayerStore);

  // 최소화한 레이어를 정의함
  const [minimizedLayer, setMinimizedLayer] = useRecoilState<string | null>(
    minimizedLayerStore,
  );

  // 최대화한 레이어를 정의함
  const [maximizedLayer, setMaximizedLayer] = useRecoilState<string | null>(
    maximizedLayerStore,
  );

  // 원래 크기 및 위치로 복원한 레이어를 정의함
  const [restoreLayer, setRestoreLayer] = useRecoilState<string | null>(
    restoreLayerStore,
  );

  // 선택한 레이어의 레이어 아이템 페이지로 이동을 정의함
  const [moveToSelectLayerItemPage, setMoveToSelectLayerItemPage] =
    useRecoilState<boolean>(moveToSelectLayerItemPageStore);

  // 레이어를 클릭함
  const handleRnd_onMouseDown = () => {
    // 선택한 레이어를 기억함
    setSelectedLayer(id);

    // 선택한 레이어의 레이어 아이템 페이지로 이동을 적용함
    setMoveToSelectLayerItemPage(true);
  };

  // 레이어의 드래그를 시작함
  const handleRnd_onDragStart = (
    event: DraggableEvent,
    data: DraggableData,
  ) => {
    // 드래그 중인 레이어를 기억함
    setDraggingLayer(id);

    // 레이어가 분할된 상태일 때만 처리함
    if (splitWay !== undefined && splitWay !== null) {
      setTimeout(() => {
        // 수정할 레이어를 정의함
        let layer: ILayer = {
          id,
          size: latestSize,
          position: latestPosition,
          splitWay: null,
        };

        // 레이어를 수정함
        setModifyLayer(layer);
      }, 200);
    }
  };

  // 레이어를 드래그한 후 멈춤
  const handleRnd_onDragStop = (event: DraggableEvent, data: DraggableData) => {
    // 수정할 레이어를 정의함
    let layer: ILayer = {
      id,
      position: {
        x: data.x,
        y: data.y,
      },
    };

    // 바탕화면의 양쪽 끝에서 레이어의 드래그가 멈춤
    if (splitDraggingLayer !== null) {
      // 바탕화면 개체를 불러옴
      let desktop = document.querySelector('#desktop') as HTMLDivElement;

      layer.latestSize = size;
      layer.latestPosition = position;
      layer.size = {
        width: (desktop?.clientWidth || 0) / 2,
        height: desktop?.clientHeight || 0,
      };
      layer.position = {
        x: splitDraggingLayer === 'left' ? 0 : (desktop?.clientWidth || 0) / 2,
        y: 0,
      };
      layer.splitWay = splitDraggingLayer;
    }

    // 레이어를 수정함
    setModifyLayer(layer);

    // 드래그 중인 레이어를 초기화함
    setDraggingLayer(null);

    // 드래그 중인 레이어를 화면 양쪽 끝으로 이동 저장소를 초기화함
    setSplitDraggingLayer(null);
  };

  // 레이어의 크기가 변경된 후 멈춤
  const handleRnd_onResizeStop = (
    event: MouseEvent | TouchEvent,
    dir: ResizeDirection,
    elementRef: HTMLElement,
    delta: ResizableDelta,
    position: Position,
  ) => {
    // 레이어의 크기를 불러옴
    let width = +elementRef.style.width.replace('px', '');
    let height = +elementRef.style.height.replace('px', '');

    // 수정할 레이어를 정의함
    let layer: ILayer = {
      id,
      size: {
        width: width,
        height: height,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    };

    // 레이어를 수정함
    setModifyLayer(layer);
  };

  // 레이어 최소화 버튼을 클릭함
  const handleMinimizeButton_onClick = () => {
    // 레이어를 최소화함
    setMinimizedLayer(id);
  };

  // 레이어 최대화 버튼을 클릭함
  const handleMaximizeButton_onClick = () => {
    // 레이어를 최대화함
    setMaximizedLayer(id);
  };

  // 레이어 최대화 복원 버튼을 클릭함
  const handleRestoreButton_onClick = () => {
    // 레이어를 원래 크기 및 위치로 복원함
    setRestoreLayer(id);
  };

  // 레이어 닫기 버튼을 클릭함
  const handleRndCloseButton_onClick = () => {
    // 레이어를 삭제함
    setRemoveLayer(id);
  };

  return (
    <Rnd
      id={id}
      size={size}
      position={position}
      minWidth={minSize!.width}
      minHeight={minSize!.height}
      dragHandleClassName="drag-handle"
      enableResizing={resizing}
      disableDragging={maximized ? true : lockPosition}
      // onClick={handleRnd_onMouseDown}
      onMouseDown={handleRnd_onMouseDown}
      onDragStart={handleRnd_onDragStart}
      onDragStop={handleRnd_onDragStop}
      onResizeStop={handleRnd_onResizeStop}
      // className={`layer-object p-0.5 bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg shadow-md opacity-0 overflow-hidden pointer-events-auto ${
      //   layerData?.onlyIncludeInTaskBar ? 'hidden-layer' : 'show-layer'
      // }`}
      style={{
        zIndex: zIndex,
        display: minimized ? 'none' : 'block',
      }}
      className="layer-base relative translate-z"
    >
      <div
        // style={{
        //   transition: 'all 0.3s cubic-bezier(0.16, 1, 0.3, 1)',
        // }}
        className="object-appear w-full h-full p-1 flex flex-col bg-white border border-gray-200 rounded-md shadow-lg shadow-gray-500/50 space-y-1 overflow-hidden"
      >
        {/* 레이어 헤더 */}
        <div className="flex-none h-9 flex justify-between items-center space-x-5 select-none">
          <div
            className={`w-full h-full flex justify-center items-center ${
              id !== selectedLayer && 'opacity-50'
            }`}
          >
            {/* 제목 */}
            <div
              onDoubleClick={
                maximized
                  ? handleRestoreButton_onClick
                  : handleMaximizeButton_onClick
              }
              // className={`drag-handle h-full flex-grow pl-4 flex justify-start items-center overflow-hidden space-x-2 ${
              //   !layerData?.positionLock && 'cursor-move'
              // }`}
              className="drag-handle grow pl-3 flex justify-start items-center space-x-1.5 overflow-hidden"
            >
              {/* 아이콘 */}
              <div className="flex justify-center items-center">
                {id === selectedLayer && (
                  <FontAwesomeIcon
                    icon={icon!}
                    className="icon-size-3 text-indigo-400 icon-shadow-md"
                  />
                )}
                {id !== selectedLayer && (
                  <FontAwesomeIcon
                    icon={icon!}
                    className="icon-size-3 text-amber-500 icon-shadow-md"
                  />
                )}
              </div>

              {/* 제목 */}
              <div className="flex justify-start items-center overflow-hidden">
                <span className="text-base font-bold text-gray-700 truncate">
                  {title}
                </span>
              </div>
            </div>

            {/* 버튼 */}
            <div className="flex-none h-full flex justify-center items-center">
              <div className="h-full flex justify-center items-center space-x-2">
                {/* 최소화 버튼 */}
                {showMinimizeButton && (
                  <div
                    onClick={handleMinimizeButton_onClick}
                    className="button-event px-1 h-full flex justify-center items-center"
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'angle-down']}
                      className="w-5 h-5 text-gray-400"
                    />
                  </div>
                )}

                {/* 최대화 버튼 */}
                {showMaximizeButton && (
                  <>
                    {maximized && (
                      <div
                        onClick={handleRestoreButton_onClick}
                        className="button-event px-1 h-full flex justify-center items-center"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'square-minus']}
                          className="w-5 h-5 text-gray-400"
                        />
                      </div>
                    )}

                    {!maximized && (
                      <div
                        onClick={handleMaximizeButton_onClick}
                        className="button-event px-1 h-full flex justify-center items-center"
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'angle-up']}
                          className="w-5 h-5 text-gray-400"
                        />
                      </div>
                    )}
                  </>
                )}

                {/* 닫기 버튼 */}
                {showCloseButton && (
                  <div
                    onClick={handleRndCloseButton_onClick}
                    className="button-event px-1 h-full flex justify-center items-center"
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'times']}
                      className="w-5 h-5 text-gray-400"
                    />
                  </div>
                )}
              </div>

              {/* 드래그용 여백 */}
              <div className="drag-handle w-3 h-full" />
            </div>
          </div>
        </div>

        {/* 레이어 내용 */}
        <div
          ref={contentRef}
          className="flex-grow bg-gray-700 rounded overflow-hidden"
        >
          {/*<ScrollArea*/}
          {/*  w={contentWidth}*/}
          {/*  h={contentHeight}*/}
          {/*  className="border border-red-500"*/}
          {/*>*/}
          {children}
          {/*{React.cloneElement(children as React.ReactElement, { layerId: id })}*/}
          {/*</ScrollArea>*/}
        </div>
      </div>
    </Rnd>
  );
};

export default LayerBase;
